<template>
  <!--
 remplacer invoice par user
 puis remplacer Invoice par User
 puis remplacer in par us
 -->
  <section>
    <div class="row mb-2">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="pen-fancy"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>
                  Liste des {{ invoices_total }} factures et avoirs créés en
                </div>
                <m-form-select
                  :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  style="width:110px"
                  class="ms-2 input-year"
                  :clearable="false"
                  v-model="year"
                ></m-form-select>
                <!--   <m-form-date
                  class="ms-2"
                  style="width:100px"
                  :name="$Utils.randomstring('annee')"
                  formatInput="YYYY"
                  v-model="year"
                  :year="true"
                  classInput="year-concours"
                ></m-form-date> -->
              </div>
              <div class="fs-6 fw-light">Liste des factures et des avoirs</div>
            </div>
          </h3>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle ms-4"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Actions
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="w-100">
              <div @click="exportAllInvoices" class="dropdown-item pointer">
                Exporter Toutes les factures
              </div>
            </li>
            <li class="w-100">
              <div @click="exportComptable" class="dropdown-item pointer">
                Export comptable
              </div>
            </li>
            <!--  <li>
              <div @click="exportExcel" class="dropdown-item pointer">
                Exporter en EXCEL
              </div>
            </li> -->
            <li class="w-100">
              <div @click="createAvoir" class="dropdown-item pointer">
                Créer un avoir
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Chiffre d'affaires</div>
            <div class="stat-subtext">du concours {{ $store.state.year }}</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.ca.color">
            {{ $store.state.stats.ca.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ ($store.state.stats.ca.nb / 100) | formatCa }}
        </div>
      </div>

      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Total des avoirs</div>
            <div class="stat-subtext">
              pour le concours {{ $store.state.year }}
            </div>
          </div>
          <div
            class="stat-percent"
            :class="$store.state.stats.totalAvoir.color"
          >
            {{ $store.state.stats.totalAvoir.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ ($store.state.stats.totalAvoir.nb / 100) | formatCa }}
        </div>
      </div>

      <div class="frame w-100 me-4">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre d'avoir</div>
            <div class="stat-subtext">inscrits</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.avoirs.color">
            {{ $store.state.stats.avoirs.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.avoirs.nb }}</div>
      </div>
      <div class="w-100"></div>
    </div>

    <div class="d-flex align-items-center mb-2">
      <m-form-text
        label="Recherche textuelle"
        v-model="filterText"
        :name="$Utils.randomstring('search')"
        autocomplete
        @input="loadDelayInvoices()"
      ></m-form-text>
      <m-form-select
        class="ms-2"
        style="width:260px;"
        label="Type de paiement"
        v-model="filterTypePaiement"
        name="filterTypePaiement"
        :items="$store.state.items_paiement_way"
        @input="loadDelayInvoices()"
      ></m-form-select>
      <m-form-select
        class="ms-2"
        style="width:260px;"
        label="Facture ou avoir"
        :name="$Utils.randomstring('type')"
        v-model="filterTypeFacture"
        :items="$store.state.items_facture_avoir"
        @input="loadDelayInvoices()"
      ></m-form-select>
    </div>
    <div class="frame">
      <m-list-simple
        tableClass="table-hover table-striped"
        ref="invoiceslist"
        :dynamic="true"
        :items="invoices"
        item-value="in_id"
        :item-height="30"
        :limit="invoices_limit"
        :skip="invoices_skip"
        :total="invoices_total"
        :pagging="pagging"
        :nbPage="nbPage"
        :nbPerPage="nbPerPage"
        :cursorOnRows="true"
        :urlExportCSV="
          `${this.$config.server_url}/backoffice/1.0/invoices/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
        "
        backgroundColorTrConditionKey="in_type"
        backgroundColorTrConditionValue="AVOIR"
        @itemclick="editInvoice"
      >
        <template v-slot:ths="{}">
          <th @click="sortTable(['in_name', 'in_firstname'])">
            <span class="pointer">Nom</span>
          </th>
          <th @click="sortTable(['in_society'])">
            <span class="pointer">Société</span>
          </th>
          <th style="min-width:100px;" @click="sortTable(['in_num'])">
            <span class="pointer">Facture</span>
          </th>
          <th style="min-width:160px;" @click="sortTable(['in_typepaiement'])">
            <span class="pointer">Type de paiement</span>
          </th>
          <th
            style="min-width:80px;"
            @click="sortTable(['in_price_ht'])"
            class="text-center"
          >
            <span class="pointer">Nb vins</span>
          </th>
          <th
            style="min-width:100px;"
            class="text-end"
            @click="sortTable(['in_price_ht'])"
          >
            <span class="pointer">Prix HT</span>
          </th>
          <th style="min-width:100px;" @click="sortTable(['in_date'])">
            <span class="pointer">Payé</span>
          </th>
          <th style="min-width:100px;" @click="sortTable(['t1.updatedAt'])">
            <span class="pointer">Dernière modif.</span>
          </th>
          <th></th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ $options.filters.formatContactNameSimple(item, "in_") }}
          </td>
          <td>
            {{ item.in_society }}
          </td>
          <td>N°{{ item.in_num }}</td>
          <td>
            {{ item.in_typepaiement }}
            <span v-if="item.in_auto && item.in_auto != '0'">
              | {{ item.in_auto }}</span
            >
          </td>
          <td class="text-center">
            {{ item.wines ? item.wines.length : 0 }}
          </td>
          <td class="text-end">
            <span v-if="item.in_type == 'AVOIR'">-</span
            >{{ item.in_price_ht | formatPriceDivisePar100 }}
          </td>
          <td v-if="item.in_payed">
            {{ item.in_payed_date | formatDateTime }}
          </td>
          <td v-else v-html="'<span class=\'text-danger\'>Non</span>'"></td>
          <td>
            {{
              item.updatedAt && item.updatedAt !== "0000-00-00"
                ? $dayjs(item.updatedAt).format("DD/MM/YYYY HH:mm")
                : " - "
            }}
          </td>
          <td>
            <div class="d-flex justify-content-end">
              <div
                @click.stop="sendFacture(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                :title="
                  `Envoyer l${
                    item.in_type === 'AVOIR' ? '\'avoir' : 'a facture'
                  } par mail`
                "
              >
                <icon color="#3fb1dc" name="envelope"></icon>
              </div>
              <div
                @click.stop="print(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                :title="
                  `Imprimer l${
                    item.in_type === 'AVOIR' ? '\'avoir' : 'a facture'
                  }`
                "
              >
                <icon name="print"></icon>
              </div>
              <!--  <div @click.stop="deleteConfirm(item)">
                <icon color="#950605" name="trash-alt"></icon>
              </div> -->
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>

    <invoice-avoir-win-edit
      v-model="invoiceWinEdit"
      :in_id="invoiceSelected.in_id"
      :routeParent="'/invoices'"
      @close="invoiceWinEdit = false"
      @winEditAction="emitWinEditInvoices"
    >
    </invoice-avoir-win-edit>
    <avoir-win-create
      v-model="avoirWinEdit"
      :in_id="0"
      @WinEditActions="AvoirWinEditActions"
    >
    </avoir-win-create>
    <m-confirm-dialog
      v-model="confirmdelete"
      :text="confirmdeleteTxt"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteInvoice()"
    ></m-confirm-dialog>
  </section>
</template>

<script>
export default {
  name: "invoices",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      invoices: [],
      invoices_limit: 1000,
      invoices_skip: 0,
      invoiceSelected: { in_id: -1 },
      filterText: "",
      filterTypePaiement: "",
      filterTypeFacture: "",
      sortOrder: "desc",
      sortFields: ["t1.updatedAt"],
      invoices_total: 0,
      invoiceWinEdit: false,
      delayFilter: null,
      nbPage: 0,
      nbPerPage: 2,
      pagging: true,
      //actions
      avoirWinEdit: false,
      confirmdelete: false,
      confirmdeleteTxt: "",
      InvoiceIdToDelete: 0,
      // stats
      stats: { avoir: {}, ca: {}, notPayed: {} }
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canFacturesAccess")) {
      this.$router.push("/");
    }
    if (this.$route.name == "invoicesedit")
      this.editInvoice({ in_id: this.$route.params.id * 1 });
    await this.loadInvoices(0);
  },
  watch: {
    $route(to, from) {},
    async year(val) {
      await this.$store.dispatch("set_year", val);
      this.loadInvoices(0);
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
    }
  },
  computed: {},
  methods: {
    async exportComptable() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/export/comptable/${this.$store.state.year}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadInvoices();
    },
    changePage(currentPage) {
      let newSkip = (currentPage - 1) * this.nbPerPage;
      this.loadUsers(newSkip);
    },
    editInvoice(row_in) {
      this.invoiceSelected = row_in;
      this.$router.push("/invoices/" + row_in.in_id).catch(err => {});
      this.invoiceWinEdit = true;
    },
    loadDelayInvoices() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadInvoices();
      }, 300);
    },
    async loadInvoices(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        in_typepaiement: this.filterTypePaiement,
        in_type: this.filterTypeFacture,
        skip: skip,
        limit: this.invoices_limit,
        sort: sort,
        in_year: this.$store.state.year
      };
      if (this.filterTypeFacture) params.in_type = this.filterTypeFacture;
      this.cancelAxiosLoadInvoices && this.cancelAxiosLoadInvoices();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/invoices",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadInvoices = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.invoices = response.data.data;
      this.invoices_total = response.data.meta.total;
      this.invoices_skip = skip;
      this.$emit("loaded", this.invoices_total);

      // calcul pourcentage
      //// nb validés
      this.stats.avoir.nb = response.data.meta.totalAvoir;
      this.stats.avoir.diff = Math.round(
        ((response.data.meta.totalAvoir - response.data.meta.totalAvoirMoinsN) *
          100) /
          response.data.meta.totalAvoirMoinsN
      );
      this.stats.avoir.color = "bg-warning";
      if (this.stats.avoir.diff > 5) this.stats.avoir.color = "bg-success";
      if (this.stats.avoir.diff < -5) this.stats.avoir.color = "bg-danger";
    },
    addInvoice() {
      this.editInvoice({ in_id: -1 });
    },
    emitWinEditInvoices(what) {
      this.$router.push("/invoices").catch(err => {});
      if (what.action === "saved" || what.action === "deleted") {
        this.loadInvoices();
        this.$store.dispatch("loadStats");
      }
    },
    createAvoir() {
      this.$router.push("/invoices/avoir").catch(err => {});
      this.avoirWinEdit = true;
    },
    exportExcel() {},
    async sendFacture(item) {
      let params = {};
      let response = await this.$axios.get(
        this.$config.server_url +
          "/backoffice/1.0/invoices/" +
          item.in_id +
          "/sendemail",
        { params }
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: "La facture a bien été envoyée",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    },

    async print(row_in) {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/${row_in.in_id}/print?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    async exportAllInvoices() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/all/print/${this.$store.state.year}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    /*     deleteConfirm(item) {
      this.confirmdelete = true;
      let txt =
        item.in_type === "FACTURE"
          ? "cette facture ? "
          : "cet avoir ? Cela va revalider les vins de l'avoir.";
      this.confirmdeleteTxt = "Voulez-vous suprimer " + txt;
      this.InvoiceIdToDelete = item.in_id;
    }, */
    /*     async deleteInvoice() {
      let response = await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/invoices/" + this.InvoiceIdToDelete
      );
      this.confirmdelete = false;
      this.confirmdeleteTxt = "";
      this.InvoiceIdToDelete = 0;
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat",
          text:
            "La facture " + response.data.data.in_num + " a bien été supprimé",
          color: "green"
        });
        this.loadInvoices();
      }
    }, */
    AvoirWinEditActions(what) {
      if ((what.action = "saved")) {
        this.loadInvoices();
        this.$store.dispatch("loadStats");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
