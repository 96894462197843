<template>
  <section>
    <div class="row mb-2">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="wine-glass-alt"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>Liste des {{ jures_total }} jurés inscrits en</div>
                <m-form-select
                  :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  style="width:110px"
                  class="ms-2 input-year"
                  :clearable="false"
                  v-model="year"
                ></m-form-select>
                <!--                 <m-form-date
                  class="ms-2"
                  style="width:100px"
                  :name="$Utils.randomstring('annee')"
                  formatInput="YYYY"
                  v-model="year"
                  :year="true"
                  classInput="year-concours"
                ></m-form-date> -->
              </div>
              <div class="fs-6 fw-light">Liste des jurés</div>
            </div>
          </h3>
        </div>
        <div>
          <button
            :disabled="
              $dayjs().isAfter($store.state.yearObj.yp_results_date, 'day')
            "
            class="btn btn-primary"
            type="button"
            @click="addJure"
          >
            Nouveau
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de jurés</div>
            <div class="stat-subtext">validés</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.jures.color">
            {{ $store.state.stats.jures.diff }} %
          </div>
        </div>
        <div class="stat-chiffre" v-html="$store.state.stats.jures.nb"></div>
      </div>
      <div class=" w-100 me-4 "></div>
      <div class=" w-100 me-4 "></div>
      <div class=" w-100 me-4 "></div>
    </div>
    <div class="d-flex mb-2">
      <div class="d-flex align-items-end">
        <m-form-text
          label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadDelayJures()"
        ></m-form-text>
        <m-form-checkbox
          class="ms-2"
          :label="getlabel()"
          :name="$Utils.randomstring('filterValid')"
          v-model="filterValid"
          @input="loadDelayJures()"
        ></m-form-checkbox>
      </div>
    </div>
    <div class="frame">
      <m-list-simple
        tableClass="table-hover table-striped"
        ref="jureslist"
        :dynamic="true"
        :items="jures"
        item-value="pa_id"
        :item-height="30"
        :limit="jures_limit"
        :skip="jures_skip"
        :total="jures_total"
        :cursorOnRows="true"
        :urlExportCSV="
          `${this.$config.server_url}/backoffice/1.0/participations/exportcsv?pa_year=${this.$store.state.year}&pa_jure=1&token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
        "
        @itemclick="goCandidat"
      >
        <template v-slot:ths="{}">
          <th @click="sortTable(['pa_society'])">
            <span class="pointer">Société</span>
          </th>
          <th @click="sortTable(['pa_name', 'pa_firstname'])">
            <span class="pointer">Contact</span>
          </th>
          <th @click="sortTable(['pa_firstparticipation'])">
            <span class="pointer">1ere inscrip.</span>
          </th>
          <th @click="sortTable(['pa_typejure'])">
            <span class="pointer">Catégorie</span>
          </th>
          <th @click="sortTable(['pa_coursdegustation'])">
            <span class="pointer">Cours dégust.</span>
          </th>
          <th @click="sortTable(['pa_liensexistant'])">
            <span class="pointer">Liens</span>
          </th>
          <!-- <th @click="sortTable(['pa_centre'])">
            <span class="pointer">Centre</span>
          </th> -->
          <!--   <th @click="sortTable([''])">
            <span class="pointer">Gaillac 17</span>
          </th> -->
          <th @click="sortTable(['pa_jure_valide'])">
            <span class="pointer">Validé</span>
          </th>
          <th></th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.pa_society }}
          </td>
          <td>
            {{ $options.filters.formatContactNameSimple(item, "pa_") }}
          </td>
          <td
            v-html="
              $options.filters.formatYesNoColored(item.pa_firstparticipation)
            "
          ></td>
          <td>
            {{
              item.pa_typejure === 9
                ? item.pa_typejure_other
                : $options.filters.formatvalue(
                    item.pa_typejure,
                    "items_typejure",
                    "uppercase",
                    "singular"
                  )
            }}
          </td>
          <td
            v-html="
              $options.filters.formatYesNoColored(item.pa_coursdegustation)
            "
          ></td>
          <td>
            {{
              item.pa_liensexistant
                ? item.pa_liensexistant_more
                  ? item.pa_liensexistant_more
                  : "Oui"
                : "Non"
            }}
          </td>
          <!-- <td>
            {{ item.pa_centre }}
          </td> -->
          <!--           <td>
            {{ item.pa_name }}
          </td> -->
          <td
            v-html="$options.filters.formatYesNoColored(item.pa_jure_valide)"
          ></td>
          <td>
            <div class="d-flex justify-content-between">
              <div
                @click.stop="sendEmailInscription(item)"
                v-tooltip
                data-bs-placement="left"
                title="Envoyer l'email de validation juré"
              >
                <icon color="#3fb1dc" name="envelope"></icon>
              </div>
              <div
                @click.stop="connectLike(item)"
                class="ms-2"
                v-tooltip
                data-bs-placement="left"
                title="Se connecter comme"
              >
                <icon name="key" color="#d7a302"></icon>
              </div>
              <!-- <div @click.stop="deleteConfirmJure(item)" class="ms-2">
                <icon color="#950605" name="trash-alt"></icon>
              </div> -->
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>

    <candidat-jure-win-edit
      v-model="candidatWinEdit"
      :pa_id="candidatSelected"
      @WinEditAction="candidatJureWinEditAction"
    >
    </candidat-jure-win-edit>
    <!--   <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer ce juré ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteItem()"
    ></m-confirm-dialog> -->
    <!-- <candidat-jure-win-edit
      v-if="row_pa"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa.pa_id"
      @WinEditAction="WinEditAction"
    ></candidat-jure-win-edit> -->
  </section>
</template>

<script>
export default {
  name: "jure",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      jures: [],
      jures_limit: 1000,
      jures_skip: 0,
      jureSelected: { pa_id: -1 },
      sortOrder: "asc",
      sortFields: ["pa_name", "pa_firstname"],
      filterText: "",
      filterValid: false,
      jures_total: 0,
      jureWinEdit: false,
      delayFilter: null,
      candidatWinEdit: false,
      candidatSelected: -1,
      // actions
      //   confirmdelete: false,
      itemToDelete: {}
      //stats
      // stats: { valid: { nb: "&nbsp;" } }
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canContactsAccess")) {
      this.$router.push("/");
    }
    if (this.$route.name == "juresedit")
      this.editJure({ pa_id: this.$route.params.id * 1 });
    await this.loadJures(0);
  },
  watch: {
    year: async function(val) {
      // console.log("val", val);
      await this.$store.dispatch("set_year", val);
      // console.log("this.$store.state.year", this.$store.state.year);
      this.loadJures();
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
    }
  },
  computed: {},
  methods: {
    getlabel() {
      return "Uniquement les " + this.$store.state.stats.jures.nb + " validés";
    },
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadJures();
    },
    goCandidat(row_pa) {
      this.candidatSelected = row_pa.pa_id;
      this.$router.push("/jures/" + row_pa.pa_id + "/edit").catch(err => {});
      this.candidatWinEdit = true;
    },
    connectLike(item) {
      window.open(
        `${this.$config.candidats_url}/autologin?login=${encodeURIComponent(
          item.lo_login
        )}&user=${this.$store.state.user.us_id}&password=${encodeURIComponent(
          "VOUsNePasserezP@@@s!"
        )}&year=${this.$store.state.year}`,
        "_blank"
      );
    },
    /*     editJure(row_pa) {
      this.jureSelected = row_pa;
      this.$router.push("/jures/" + row_pa.pa_id + "/edit");
      this.jureWinEdit = true;
    }, */
    loadDelayJures() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadJures();
      }, 300);
    },
    async loadJures(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        skip: skip,
        limit: this.jures_limit,
        sort: sort,
        pa_year: this.$store.state.year,
        pa_jure: true,
        moreInfos: true
      };
      if (this.filterValid) params.pa_jure_valide = true;
      this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/participations",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadJures = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.jures = response.data.data;
      this.jures_total = response.data.meta.total;
      //this.jures_total = this.jures.length;
      this.jures_skip = skip;
      // calcul pourcentage
      //// nb validés
      // this.stats.valid.nb = response.data.meta.totalValid;
      // this.stats.valid.diff = Math.round(
      //   ((response.data.meta.totalValid - response.data.meta.totalValidMoinsN) *
      //     100) /
      //     response.data.meta.totalValidMoinsN
      // );
      // this.stats.valid.color = "bg-warning";
      // if (this.stats.valid.diff > 5) this.stats.valid.color = "bg-success";
      // if (this.stats.valid.diff < -5) this.stats.valid.color = "bg-danger";
    },
    addJure() {
      //this.editJure({ pa_id: -1 });
      this.candidatSelected = -1;
      this.$router.push("/jures/-1/edit").catch(err => {});
      this.candidatWinEdit = true;
    },
    candidatJureWinEditAction(what) {
      if (what.action === "saved") {
        this.$router.push("/jures").catch(err => {});
        this.$store.dispatch("loadStats");
      }
      if (what.action === "canceled") {
        this.$router.push("/jures").catch(err => {});
      }
      this.loadJures();
    },
    async sendEmailInscription(item) {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/backoffice/1.0/participations/" +
          item.pa_id +
          "/sendemail/validation/jure"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: "L'email a bien été envoyé",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    }
    /*     deleteConfirmJure(item) {
      this.confirmdelete = true;
      this.itemToDelete = item;
    }, */
    /*     async deleteItem() {
      let response = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/participations/" +
          this.itemToDelete.pa_id
      );
      this.confirmdelete = false;
      this.itemToDelete = {};
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Juré",
          text:
            "Le juré " +
            response.data.data.pa_name +
            " " +
            response.data.data.pa_firstname +
            " a bien été supprimé",
          color: "green"
        });
        this.loadJures();
      }
    } */
  }
};
</script>

<style lang="scss" scoped></style>
