<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <h3 class="mb-0 d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="user-friends"></icon>
            </div>
            <div>
              <div>
                {{
                  row_pa.pa_id > 0
                    ? "Modification fiche candidat / juré"
                    : "Création d'une fiche candidat / juré"
                }}
              </div>
              <div class="fs-6 fw-light">
                Les champs avec une astérisque sont obligatoires
              </div>
            </div>
          </h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="frame" v-if="pa_id < 1">
                <h4>Candidat déjà éxistant ?</h4>
                <div>
                  <m-form-combobox
                    v-model="loginSelected"
                    style="max-width:870px"
                    label="Candidat/login"
                    :store-url="$config.server_url + '/backoffice/1.0/logins'"
                    item-value="lo_id"
                    :item-text="$options.filters.EmailLogin"
                    :store-params="{
                      sort: 'lo_login ASC'
                    }"
                  ></m-form-combobox>
                </div>
              </div>
              <form-candidat
                ref="formCondidatJure"
                :pa_id="pa_id"
                :row_pa_tocopy="participationToCopy"
                from="backoffice"
                @formCandidatJureActions="emitFormCandidatJureActions"
                @participationLoaded="emitPparticipationLoaded"
              ></form-candidat>
              <div class="frame ">
                <h4>Email et mot de passe</h4>
                <div class="d-flex mt-2">
                  <m-form-text
                    label="Login *"
                    style="width:330px"
                    :name="$Utils.randomstring('lo_login')"
                    v-model="login.lo_login"
                  ></m-form-text>
                  <m-form-password
                    class="ms-2"
                    style="width:50%"
                    :name="$Utils.randomstring('pass')"
                    :showRules="true"
                    valid="shadow"
                    display="row"
                    :rules="rulesPassword"
                    :required="false"
                    @validPassword="validPassword"
                  ></m-form-password>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5">
                    <m-form-text
                      v-if="$Utils.isAdmin()"
                      class="mb-2"
                      label="Code analytique"
                      :name="$Utils.randomstring('lo_analytique_code')"
                      v-model="login.lo_analytique_code"
                    ></m-form-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <!-- <button
            type="button"
            class="btn btn-danger"
            :disabled="row_pa.pa_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer ce candidat ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteCandidat()"
    ></m-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "CandidatJureWinEdit",
  components: {},
  props: {
    pa_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      confirmdelete: false,
      row_pa: { login: {} },
      //mot de passe
      password1: "",
      isValidPassword: true,
      errPassword: [],
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      loginSelected: { lo_login: "", lo_pass: "" },
      login: { lo_login: "", lo_pass: "" },
      participationToCopy: 0
    };
  },
  watch: {
    loginSelected(v) {
      if (!v) this.login = { lo_login: "", lo_pass: "" };
      else this.participationToCopy = this.loginSelected.lo_id;
    },
    value(v) {
      if (v) {
        this.participationToCopy = 0;
        this.jureValideConfirmed = false;
        this.loginSelected = { lo_login: "", lo_pass: "" };
        this.loadCandidat();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadCandidat() {
      /*  this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/participations/" + this.pa_id,
        {
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadJures = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.row_pa = response.data.data;
      if (!this.row_pa.login) this.row_pa.login = { lo_pass: "" };
      this.row_pa.login.lo_pass = ""; */
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },
    emitPparticipationLoaded(row_pa) {
      this.row_pa = row_pa;
      if (!this.row_pa.login) this.row_pa.login = { lo_pass: "" };
      this.row_pa.login.lo_pass = "";
      if (!this.row_pa.login.lo_login) this.row_pa.login = this.loginSelected;
      //   this.loginSelected = this.row_pa.login;
      this.login = this.row_pa.login;
    },
    tryToSaveWin() {
      /*** vérification du mot de passe */
      // if (!this.isValidPassword) {
      //   for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
      //     this.$store.dispatch("showToast", {
      //       title: "Mot de passe invalide ",
      //       text: this.errPassword[ierr],
      //       color: "red"
      //     });
      //   }
      // } else {
      // this.row_pa.login.lo_login = this.row_pa.login.lo_login.trim();

      this.login.lo_pass = this.password1;
      this.$refs.formCondidatJure.tryToSaveWin(this.login, this.errPassword);
      // }
    },
    emitFormCandidatJureActions(what) {
      this.$emit("input", false);
      this.$emit("WinEditAction", { action: "saved", row_pa: what.row_pa });
    },
    /* deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteCandidat() {
      this.$refs.formCondidatJure.deleteCandidat();
    }, */
    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditAction", { action: "canceled" });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-dialog {
  .modal-content {
    background-color: #eceff0;
  }
  .modal-header,
  .modal-footer {
    border: 0;
  }
}
</style>
