import { render, staticRenderFns } from "./CandidatJureWinEdit.vue?vue&type=template&id=dc5afad4&scoped=true&"
import script from "./CandidatJureWinEdit.vue?vue&type=script&lang=js&"
export * from "./CandidatJureWinEdit.vue?vue&type=script&lang=js&"
import style0 from "./CandidatJureWinEdit.vue?vue&type=style&index=0&id=dc5afad4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc5afad4",
  null
  
)

export default component.exports