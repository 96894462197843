<template>
  <section class="">
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="bis d-flex align-items-center">
        <div
          class="icon-title d-flex align-items-center justify-content-center"
        >
          <icon width="30" height="30" name="mail-bulk"></icon>
        </div>
        <div>
          <div class="d-flex">
            <div>
              Liste des {{ mailinglists_total }} mailinglistes du concours
            </div>
            <m-form-select
              :name="$Utils.randomstring('items_years')"
              :items="$store.state.items_years"
              style="width:110px"
              class="ms-2 input-year"
              :clearable="false"
              v-model="year"
            ></m-form-select>
            <!-- <m-form-date
              class="ms-2"
              style="width:100px"
              :name="$Utils.randomstring('annee')"
              formatInput="YYYY"
              v-model="year"
              :year="true"
              classInput="year-concours"
            ></m-form-date> -->
          </div>
          <div class="fs-6 fw-light"></div>
        </div>
      </h3>
      <button class="btn btn-primary" @click="addMailinglist">
        Nouveau
      </button>
    </div>
    <div class="d-flex align-items-center mb-2">
      <m-form-text
        label="Recherche textuelle"
        v-model="filterText"
        :name="$Utils.randomstring('search')"
        autocomplete
        @input="loadMailinglists()"
      ></m-form-text>
    </div>
    <div class="frame">
      <m-list-simple
        class="mt-4"
        tableClass="table-hover table-striped"
        ref="mailinglistslist"
        :items="mailinglists"
        item-value="ml_id"
        :item-height="30"
        :limit="1000"
        :skip="mailinglists_skip"
        :total="mailinglists_total"
        :small="false"
        :cursorOnRows="true"
        @itemclick="editMailinglist"
      >
        <template v-slot:ths="{}">
          <th @click="sortTable(['t1.createdAt'])">
            <span class="pointer">Créée le</span>
          </th>
          <th @click="sortTable(['ml_object'])">
            <span class="pointer">Titre</span>
          </th>
          <th @click="sortTable(['t1.createdAt'])">
            <span class="pointer">Nb contacts</span>
          </th>
          <th @click="sortTable(['ml_sended'])">
            <span class="pointer">Envoyé</span>
          </th>
          <th style="width:100px">&nbsp;</th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.createdAt | formatDate }}
          </td>
          <td>
            {{ item.ml_object }}
          </td>
          <td>
            {{ item.nbContacts }}
          </td>
          <!--  <td>
            <div v-for="copy in item.ml_copy.split(',')" :key="copy">
              {{ copy }}
            </div>
          </td> -->
          <td>
            {{
              item.ml_sended
                ? "Oui le :" + $options.filters.formatDate(item.ml_date_sended)
                : "Non"
            }}
          </td>
          <td class="d-flex">
            <div
              @click.stop="duplicate(item)"
              class="me-3"
              v-tooltip
              data-bs-placement="left"
              title="Dupliquer la mailingliste pour l'année en cours"
            >
              <icon name="copy"></icon>
            </div>
            <div
              @click.stop="deleteConfirm(item)"
              v-tooltip
              data-bs-placement="left"
              title="Effacer la mailinglist"
            >
              <icon color="#950605" name="trash-alt"></icon>
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>
    <mailinglist-win-edit
      v-model="mailinglistsWinEdit"
      :ml_id="mailinglistsSelected.ml_id"
      @saved="emailWinEditAction"
      @deleted="emailWinEditAction"
      @canceled="emailWinEditAction"
    ></mailinglist-win-edit>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Voulez-vous supprimer cette mailingliste ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteMailinglist()"
    ></m-confirm-dialog>
  </section>
</template>

<script>
export default {
  name: "Mailinglists",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      mailinglists: [],
      mailinglists_skip: 0,
      mailinglists_total: 0,
      mailinglistsWinEdit: false,
      mailinglistsSelected: {},
      filterText: "",
      sortOrder: "desc",
      sortFields: ["t1.createdAt"],
      confirmdelete: false,
      mailinglistsIdToDelete: 0
    };
  },
  mounted() {
    if (!this.$Utils.hasRight("canMailinglistAccess")) {
      this.$router.push("/");
    }
    // if (this.$route.)
    if (this.$route.name == "MailinglistsWinEdit") {
      this.editMailinglist({ ml_id: this.$route.params.id * 1 });
    } else {
      this.loadMailinglists();
    }
  },
  watch: {
    year: async function(val) {
      await this.$store.dispatch("set_year", val);
      this.loadMailinglists();
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
    }
  },
  methods: {
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadMailinglists();
    },
    async loadMailinglists(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        skip: skip,
        limit: 1000,
        sort: sort,
        ml_year: this.year
      };
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/mailinglists",
        { params }
      );
      this.mailinglists = response.data.data;
      this.mailinglists_total = this.mailinglists.length;
    },
    addMailinglist() {
      this.mailinglistsSelected = { ml_id: -1 };
      this.$router
        .push("/mailinglists/" + this.mailinglistsSelected.ml_id)
        .catch(err => {});
      this.mailinglistsWinEdit = true;
    },
    editMailinglist(item) {
      this.mailinglistsSelected = item;
      this.$router.push("/mailinglists/" + item.ml_id).catch(err => {});
      this.mailinglistsWinEdit = true;
    },
    emailWinEditAction(em_id) {
      this.$router.push("/mailinglists").catch(err => {});
      this.loadMailinglists();
    },
    deleteConfirm(item) {
      this.confirmdelete = true;
      this.mailinglistsIdToDelete = item.ml_id;
    },
    async deleteMailinglist() {
      let response = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/mailinglists/" +
          this.mailinglistsIdToDelete
      );
      if (response.data.data.ml_id) {
        this.$store.dispatch("showToast", {
          title: "Suppression",
          text: "La mailinglist a bien été supprimé",
          color: "green"
        });
        this.loadMailinglists();
      }
      this.confirmdelete = false;
      this.mailinglistsIdToDelete = 0;
    },
    async duplicate(item) {
      let response = await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/mailinglists/duplicate/" +
          item.ml_id
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Duplication",
          text: "La mailinglist a bien été dupliquée... dans l'année courante",
          color: "green"
        });
        this.loadMailinglists();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-alert-enter-active,
.slide-alert-leave-active {
  transition: all 0.5s ease;
}
// une fois entrée et avant de partir
.slide-alert-enter-to,
.slide-alert-leave {
  transform: translateY(100px);
}
// avant de rentrer et une fois partie
.slide-alert-enter,
.slide-alert-leave-to {
  transform: translateY(-100px);
}
</style>
