<template>
  <!--
 remplacer actualite par user
 puis remplacer Actualite par User
 puis remplacer act par us
 -->
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h3 class="mb-0 d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="newspaper"></icon>
            </div>
            <div>
              <div>
                {{
                  row_act.act_id > 0
                    ? "Modification actualité"
                    : "Création d'une actualité"
                }}
              </div>
              <div class="fs-6 fw-light">
                Les champs avec une astérisque sont obligatoires
              </div>
            </div>
          </h3>
          <div class="d-flex align-items-center" v-if="$Utils.isAdmin()">
            <div v-if="row_act.act_online" class="me-2">
              Actualité en ligne
            </div>
            <div v-else class="me-2">Actualité pas en ligne</div>
            <label class="switch">
              <input v-model="row_act.act_online" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="frame">
                <div class="row">
                  <div class="col-md-6">
                    <h4>Données</h4>
                    <m-form-text
                      label="Titre *"
                      class="mb-2"
                      :name="$Utils.randomstring('act_title')"
                      v-model="row_act.act_title"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                    <m-form-text
                      label="Sous-titre *"
                      class="mb-2"
                      :name="$Utils.randomstring('act_subtitle')"
                      v-model="row_act.act_subtitle"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <m-form-text
                          label="Date affichée sur la site"
                          :name="$Utils.randomstring('act_date')"
                          v-model="row_act.act_date"
                        ></m-form-text>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-6">
                        <m-form-date
                          label="Date début affichage"
                          :name="$Utils.randomstring('act_datestart')"
                          v-model="row_act.act_datestart"
                        ></m-form-date>
                      </div>
                      <div class="col-md-6">
                        <m-form-date
                          label="Date fin affichage"
                          :name="$Utils.randomstring('act_dateend')"
                          v-model="row_act.act_dateend"
                        ></m-form-date>
                      </div>
                    </div>
                    <h4>Fichier attaché</h4>
                    <!-- <h5>Fichier téléchargeable</h5> -->
                    <m-form-file
                      class=""
                      ref="inputFile"
                      id="file"
                      @inputfile="fileJusteSelected"
                    />
                    <div
                      class="d-flex align-items-center mt-2"
                      v-if="row_act.act_file"
                    >
                      <m-form-checkbox
                        label="Effacer"
                        :name="$Utils.randomstring('deleteFile')"
                        v-model="deleteFile"
                      ></m-form-checkbox>
                      <button
                        @click="downloadFile"
                        class="btn btn-primary btn-sm ms-3"
                      >
                        Télécharger
                      </button>
                    </div>

                    <h4 class="mt-3">Image</h4>
                    <m-form-file
                      class=""
                      ref="inputFile"
                      id="file2"
                      @inputfile="fileJusteSelected2"
                    />
                    <div
                      class="d-flex align-items-center mt-2"
                      v-if="row_act.act_image"
                    >
                      <m-form-checkbox
                        label="Effacer"
                        :name="$Utils.randomstring('deleteFile2')"
                        v-model="deleteFile2"
                      ></m-form-checkbox>
                    </div>

                    <div
                      class="container-image-actu"
                      :style="
                        `background-image:url('${
                          filesSelected2.binary
                            ? filesSelected2.binary
                            : getFileUrl()
                        }');`
                      "
                    ></div>
                  </div>
                  <div class="col-md-6">
                    <h4>Textes</h4>
                    <m-form-text
                      class="mb-2"
                      label="Texte court"
                      :name="$Utils.randomstring('act_resume')"
                      v-model="row_act.act_resume"
                      type="textarea"
                      :rows="5"
                    ></m-form-text>
                    <m-form-text
                      label="Texte long"
                      :name="$Utils.randomstring('act_contenu')"
                      v-model="row_act.act_contenu"
                      type="textarea"
                      :rows="10"
                    ></m-form-text>
                    <m-form-text
                      label="Lien"
                      :name="$Utils.randomstring('act_link')"
                      v-model="row_act.act_link"
                    ></m-form-text>
                    <m-form-text
                      label="Description du lien"
                      :name="$Utils.randomstring('act_link_desc')"
                      v-model="row_act.act_link_desc"
                    ></m-form-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button
            type="button"
            class="btn btn-outline-danger"
            :disabled="row_act.act_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button>
          <div class="ms-auto"></div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer ce actualite ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "ActualitesWinEdit",
  components: {},
  props: {
    act_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      confirmdelete: false,
      row_act: { act_rights: {}, act_type: "" },
      today: new Date(),
      filesSelected: null,
      deleteFile: false,
      filesSelected2: { image: null, binary: null },
      deleteFile2: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.getActualite();
        this.filesSelected2 = { image: null, binary: null };
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    getFileUrl() {
      return `${this.$config.server_url}/commons/1.0/actualites/${this.row_act.act_id}/image`;
    },
    async getActualite() {
      let params = {};
      if (this.act_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/actualites/" + this.act_id,
        { params }
      );
      let row_act = response.data.data;
      // console.log("response.data", response.data);
      // console.log("row_act", row_act);
      this.row_act = row_act;
      // if (
      //   !this.$dayjs(this.row_act.act_datestart).isValid() ||
      //   this.row_act.act_datestart === "0000-00-00 00:00:00"
      // )
      //   this.row_act.act_datestart = this.today;
      // if (
      //   !this.$dayjs(this.row_act.act_date).isValid() ||
      //   this.row_act.act_date === "0000-00-00 00:00:00"
      // )
      //   this.row_act.act_date = this.$dayjs(this.row_act.act_datestart).format(
      //     "DD/MM/YYYY"
      //   );
      // if (
      //   !this.$dayjs(this.row_act.act_dateend).isValid() ||
      //   this.row_act.act_dateend === "0000-00-00 00:00:00"
      // )
      //   this.row_act.act_dateend = "0000-00-00";
    },
    fileJusteSelected(files) {
      this.filesSelected = files[0];
    },
    fileJusteSelected2(files) {
      if (!files.length) return;
      this.filesSelected2.image = files[0];
      let reader = new FileReader();
      let that = this;
      reader.onload = (function(theFile) {
        return function(e) {
          that.filesSelected2.binary = e.target.result;
          /* that.files[num].url = e.target.result; */
        };
      })(files[0]);
      reader.readAsDataURL(files[0]);
    },
    downloadFile() {
      window.open(
        `${this.$config.server_url}/web/1.0/actualites/${this.row_act.act_id}/file`,
        "_blank"
      );
    },
    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "act_title", text: "Titre" }
        // { field: "act_subtitle", text: "sous-titre" }
        // { field: "act_contenu", text: "text long" },
        // { field: "act_resume", text: "text court" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_act[field.field]) err.push(field);
      }

      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    trimFields() {
      let tabField = ["act_title", "act_subtitle"];
      for (let i = 0; i < tabField.length; i++) {
        const field = tabField[i];
        this.row_act[field] = this.row_act[field].trim();
      }
    },
    async saveWin() {
      this.trimFields();
      let response;
      if (this.row_act.act_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/backoffice/1.0/actualites/" +
            this.row_act.act_id,
          this.row_act
        );
      } else {
        this.row_act.user = this.$store.state.user;
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/actualites",
          this.row_act
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_act = response.data.data;

      // les fichiers
      if (this.filesSelected && !this.deleteFile) {
        await this.saveFiles(response.data.data, 1);
      }
      if (this.deleteFile) {
        await this.godeleteFile(1);
      }
      if (this.filesSelected2.image && !this.deleteFile2) {
        await this.saveFiles(response.data.data, 2);
      }
      if (this.deleteFile2) {
        await this.godeleteFile(2);
      }

      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "saved", date: row_act });
    },
    async saveFiles(row, num) {
      // if (!this.filesSelected) return;
      let formData = new FormData();
      if (num === 1)
        formData.append("file", this.filesSelected, this.filesSelected.name);
      if (num === 2)
        formData.append(
          "file",
          this.filesSelected2.image,
          this.filesSelected2.image.name
        );
      await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/actualites/" +
          row.act_id +
          "/files/" +
          num,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.filesSelected = null;
    },
    async godeleteFile(num) {
      await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/actualites/" +
          this.row_act.act_id +
          "/files/" +
          num
      );
      this.deleteFile = false;
      this.filesSelected = null;
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "canceled" });
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/actualites/" +
          this.row_act.act_id
      );
      this.confirmdelete = false;
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "deleted" });
    }
  }
};
</script>

<style scoped lang="scss">
// .modal {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .active {
//   //min-width: 150px;
//   input {
//     width: auto;
//   }
// }
.container-image-actu {
  height: 200px;
  background-position: center;
  background-size: cover;
}
</style>
