<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div>
            <h3 class="mb-0 d-flex align-items-center">
              <div
                class="icon-title d-flex align-items-center justify-content-center"
              >
                <icon width="30" height="30" name="shopping-cart"></icon>
              </div>
              <div>
                <div>
                  {{
                    row_or.or_id > 0
                      ? "Modification d'une commande"
                      : "Création d'une commande"
                  }}
                </div>
                <div class="fs-6 fw-light">
                  Étape 1 / 2
                </div>
              </div>
            </h3>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-8">
                <div class="d-flex justify-content-between">
                  <div>
                    <h4>Candidat</h4>
                  </div>
                  <!--                   <div>
                    <button
                      @click="editCandidatJure"
                      class="btn btn-primary btn-sm"
                      :disabled="!loSeleted || !loSeleted.pa_id"
                    >
                      Modifier
                    </button>
                  </div> -->
                </div>
                <div>
                  <m-form-combobox
                    v-model="loSeleted"
                    label="Candidat"
                    :store-url="
                      $config.server_url + '/backoffice/1.0/orders/logins'
                    "
                    item-value="lo_id"
                    :item-text="$options.filters.formatParticipationLogin"
                    :store-params="{}"
                    :item-text-params="'lo_'"
                  ></m-form-combobox>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <h5>Adresse de livraison</h5>
                    <m-form-text
                      class="mb-2"
                      label="Nom de l'entreprise ou de l'exploitation *"
                      :name="$Utils.randomstring('or_society_liv')"
                      v-model="row_or.or_society_liv"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Civilité *"
                          :name="$Utils.randomstring('or_civility_liv')"
                          v-model="row_or.or_civility_liv"
                        ></m-form-text>
                      </div>
                      <div class="mx-1">
                        <m-form-text
                          label="Nom *"
                          :name="$Utils.randomstring('or_name_liv')"
                          v-model="row_or.or_name_liv"
                        ></m-form-text>
                      </div>
                      <div class="">
                        <m-form-text
                          label="Prénom *"
                          :name="$Utils.randomstring('or_firstname_liv')"
                          v-model="row_or.or_firstname_liv"
                        ></m-form-text>
                      </div>
                    </div>
                    <m-form-text
                      class="mb-1"
                      label="Adresse *"
                      :name="$Utils.randomstring('or_address1_liv')"
                      v-model="row_or.or_address1_liv"
                    ></m-form-text>
                    <m-form-text
                      class="mb-2"
                      :name="$Utils.randomstring('or_address2_liv')"
                      v-model="row_or.or_address2_liv"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Code postal *"
                          :name="$Utils.randomstring('or_zip_liv')"
                          v-model="row_or.or_zip_liv"
                        ></m-form-text>
                      </div>
                      <div class="ms-2 *">
                        <m-form-text
                          label="Ville *"
                          :name="$Utils.randomstring('or_city_liv')"
                          v-model="row_or.or_city_liv"
                        ></m-form-text>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h5>Adresse de facturation</h5>
                    <m-form-text
                      class="mb-2"
                      label="Nom de l'entreprise ou de l'exploitation *"
                      :name="$Utils.randomstring('or_society_fac')"
                      v-model="row_or.or_society_fac"
                    ></m-form-text>
                    <m-form-text
                      class="mb-1"
                      label="Adresse *"
                      :name="$Utils.randomstring('or_address1_fac')"
                      v-model="row_or.or_address1_fac"
                    ></m-form-text>
                    <m-form-text
                      class="mb-2"
                      :name="$Utils.randomstring('or_address2_fac')"
                      v-model="row_or.or_address2_fac"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Code postal *"
                          :name="$Utils.randomstring('or_zip_fac')"
                          v-model="row_or.or_zip_fac"
                        ></m-form-text>
                      </div>
                      <div class="ms-2">
                        <m-form-text
                          label="Ville *"
                          :name="$Utils.randomstring('or_city_fac')"
                          v-model="row_or.or_city_fac"
                        ></m-form-text>
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Tél mobile *"
                          :name="$Utils.randomstring('or_phone_mobile')"
                          v-model="row_or.or_phone_mobile"
                        ></m-form-text>
                      </div>
                      <div class="ms-2 *">
                        <m-form-text
                          label="Tél fixe"
                          :name="$Utils.randomstring('or_phone_fix')"
                          v-model="row_or.or_phone_fix"
                        ></m-form-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h4>Données</h4>
                <m-form-date
                  class="mb-2"
                  label="Date de la commande"
                  :name="$Utils.randomstring('or_date')"
                  formatInput="DD/MM/YYYY"
                  v-model="row_or.or_date"
                ></m-form-date>
                <div class="d-flex w-100">
                  <m-form-select
                    label="Enroulement *"
                    class="mb-2 w-50"
                    :items="$store.state.items_interieurexterieur"
                    v-model="row_or.or_interieurexterieur"
                    :name="$Utils.randomstring('or_interieurexterieur')"
                  ></m-form-select>
                  <m-form-select
                    label="Sens d'enroulement *"
                    class="mb-2 w-50"
                    :items="$store.state.items_sensenroulement"
                    v-model="row_or.or_sensenroulement"
                    :name="$Utils.randomstring('or_sensenroulement')"
                  ></m-form-select>
                </div>
                <m-form-text
                  label="Mandrin (en mm) *"
                  type="number"
                  v-model="row_or.or_mandrin"
                  :name="$Utils.randomstring('or_mandrin')"
                ></m-form-text>
              </div>
            </div>
          </div>
          <div class="frame">
            <h4>La commande</h4>
            <p>
              Voici la liste des vins où vous avez gagné une médaille,
              sélectionnez le nombre de macarons à commander par vin
            </p>
            <m-list-simple
              tableClass="table-hover table-striped"
              style="overflow: visible;"
              ref="wineslist"
              :dynamic="true"
              :items="wines"
              item-value="wi_id"
              :item-height="50"
              :total="wines.length"
            >
              <template v-slot:ths="{}"> </template>
              <template v-slot:tds="{ item }">
                <td class="d-flex align-items-center">
                  <div class="p-2">
                    <img
                      class="img-fluid img-macaron"
                      :src="
                        `${
                          $config.server_url
                        }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
                          item.wi_medaille,
                          'items_medailles',
                          'lowercase',
                          'singular'
                        )}/${item.wi_year}`
                      "
                      alt="image macaron"
                    />
                  </div>
                  <div class="ms-3">
                    <div>{{ item | formatWineName }}</div>
                    <h5>
                      <span>{{ item.denomination.de_name }} - </span>
                      <span>{{ item.wi_couleur | formatWineColor }} - </span>
                      <span>{{ item.wi_millesime | formatWineMillesime }}</span>
                    </h5>
                    <div>
                      Nombre de vins déclarés :
                      <!-- {{ item.nbBouteille }} -->
                      <div
                        v-for="(contenant, index) in item.contenants"
                        :key="index"
                      >
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                      </div>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <div>Concours {{ item.wi_year }}</div>
                </td>
                <td class="align-middle">
                  <m-form-select
                    label="Nombre de macarons"
                    :name="$Utils.randomstring('nbMacaron')"
                    :items="$store.state.items_nbMacaron"
                    v-model="item.nbMacaron"
                    @input="calculNbMacaron(item)"
                  ></m-form-select>
                </td>
              </template>
            </m-list-simple>
            <results-order-macaron
              ref="resultMacaron"
              :row_or="row_or"
              @changetotalOrder="emitChangetotalOrder"
            ></results-order-macaron>
            <!-- <div class="row">
              <div class="col-md-5 offset-md-7">
                <div>
                  Nombre de macaron or :
                  {{ row_or.or_or | formatChiffreSimple }}
                </div>
                <div>
                  Nombre de macaron Argent :
                  {{ row_or.or_argent | formatChiffreSimple }}
                </div>
                <div>
                  Nombre de macaron Excellence :
                  {{ row_or.or_excellence | formatChiffreSimple }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-7">
                <div>
                  Sous-total HT : {{ this.totalMacaron / 10000 }} lot(s) de 10
                  000 x
                  {{ row_or.yp_macarons_price | formatPriceDivisePar100 }}
                  = {{ totalPriceHT | formatPriceDivisePar100 }} HT
                </div>
                <div>
                  Frais de port :
                  <span
                    @click="showFraisDePort = true"
                    v-if="!showFraisDePort"
                    class="color-red pointer"
                    >Voir les frais de port à titre indicatif</span
                  >
                  <span
                    @click="showFraisDePort = false"
                    v-if="showFraisDePort"
                    class="color-red pointer"
                    >{{ fraisDePort }} €</span
                  >
                </div>
              </div>
              <div class="col-md-5">
                <div class="frame bg-gray rounded-0">
                  <div class="d-flex align-items-center">
                    <div>Total :</div>
                    <h2 class="color-red ms-2">
                      {{ totalPriceHT | formatPriceDivisePar100 }} HT
                    </h2>
                  </div>
                  <div class="color-red ">
                    (Frais de port en sus indiqués sur la facture à venir)
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <!--           <button
            type="button"
            class="btn btn-outline-danger"
            :disabled="row_wi.wi_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Étape 2 / 2
          </button>
        </div>
      </div>
    </div>
    <!--     <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer ce wine ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog> -->
    <!--     <candidat-jure-win-edit
      v-if="row_pa2"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa2.pa_id"
      @WinEditAction="WinEditCandidatJureActions"
    ></candidat-jure-win-edit> -->
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
// import MFormText from "../components/MFormText.vue";
export default {
  name: "OrderNew1",
  components: {},
  props: {
    or_id: Number,
    /*     row_pa: {
      default: function() {
        return {};
      },
      type: Object
    }, */
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      // confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      row_or: { or_or: 0, or_argent: 0, or_excellence: 0 },
      wines: [],
      // row_pa2: {},
      candidatJureWinEdit: false,
      // nbMacaron1: 0, //or
      // nbMacaron2: 0, //argent
      // nbMacaron3: 0, //excellence
      totalMacaron: 0,
      totalPriceHT: 0,
      showFraisDePort: false,
      fraisDePort: 0,
      loSeleted: {}
    };
  },
  watch: {
    async value(v) {
      if (v) {
        //if (this.row_pa) this.row_pa2 = this.row_pa;
        this.showFraisDePort = false;
        this.wines = [];
        this.row_or = { or_or: 0, or_argent: 0, or_excellence: 0 };
        await this.loadOrder();
        if (!this.or_id) this.loSeleted = {};
        else this.loSeleted = this.row_or.login;
        this.loSeleted.pa_society = this.row_or.or_society_fac;
        // console.log("this.row_or", this.row_or);
        //this.contenants = [];
        //  this.loadDenominations();
        //this.loadWines();
      }
    },
    loSeleted(v) {
      // this.row_pa2 = v;
      // this.row_or.participation = v;
      // pour la création d'une commande, copie des champs adresse de la participation dans l'order
      if (this.or_id < 1 && v) {
        this.row_or.or_society_fac = v.pa_society_fac
          ? v.pa_society_fac
          : v.pa_society;
        this.row_or.or_address1_fac = v.pa_address1_fac
          ? v.pa_address1_fac
          : v.pa_address1;
        this.row_or.or_address2_fac = v.pa_address2_fac
          ? v.pa_address2_fac
          : v.pa_address2;
        this.row_or.or_city_fac = v.pa_city_fac ? v.pa_city_fac : v.pa_city;
        this.row_or.or_zip_fac = v.pa_zip_fac ? v.pa_zip_fac : v.pa_zip;
        this.row_or.or_society_liv = v.pa_society;
        this.row_or.or_civility_liv = v.pa_civility;
        this.row_or.or_name_liv = v.pa_name;
        this.row_or.or_firstname_liv = v.pa_firstname;
        this.row_or.or_address1_liv = v.pa_address1;
        this.row_or.or_address2_liv = v.pa_address2;
        this.row_or.or_city_liv = v.pa_city;
        this.row_or.or_zip_liv = v.pa_zip;
      }
      this.loadWines();
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    calculNbMacaron(wine) {
      // calcul le nombre de macaron demandé par couleur (or argent excellence) et enregistre directement dan row_or
      let nb = 0;
      for (let iwi = 0; iwi < this.wines.length; iwi++) {
        const row_wi = this.wines[iwi];
        if (row_wi.wi_medaille === wine.wi_medaille) {
          if (row_wi.nbMacaron) nb += row_wi.nbMacaron;
        }
      }
      // console.log("nb", nb);
      let medaille = this.$options.filters.formatvalue(
        wine.wi_medaille,
        "items_medailles",
        "lowercase",
        "singular"
      );
      this.row_or["or_" + medaille] = nb;
      this.$refs.resultMacaron.calculTotal();
    },
    /*     calculTotal() {
      this.totalMacaron =
        this.row_or.or_or + this.row_or.or_argent + this.row_or.or_excellence;
      // console.log("this.totalMacaron", this.totalMacaron);
      this.totalPriceHT =
        (this.totalMacaron / 10000) * this.row_or.yp_macarons_price;
      // console.log(
      //   "this.$store.state.chiffres.macaronsPrice",
      //   this.$store.state.chiffres.macaronsPrice
      // );
    }, */
    async loadOrder() {
      // console.log("je passe", this.or_id);
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/orders/" + this.or_id
      );
      this.row_or = response.data.data;
      // this.totalPriceHT = this.row_or.or_price_ht ;
      // if (this.row_or.participation) this.row_pa2 = this.row_or.participation;
      // else this.row_pa2 = { login: {} };

      await this.loadWines();
      // this.calculTotal();
      // this.totalMacaron =
      //   this.row_or.or_or + this.row_or.or_argent + this.row_or.or_excellence;
      // this.totalPriceHT =
      //   (this.totalMacaron / 10000) * this.$store.state.chiffres.macaronsPrice;
    },
    emitChangetotalOrder(results) {
      this.row_or.or_price_ht = results.ht;
      this.row_or.or_price_ttc = results.ttc;
      this.row_or.or_price_tva = results.tva;
    },
    async loadWines() {
      //if (!this.row_pa2 || !this.row_pa2.pa_id) return;
      if (!this.row_or.lo_id && (!this.loSeleted || !this.loSeleted.lo_id))
        return;
      let loId = this.row_or.lo_id ? this.row_or.lo_id : this.loSeleted.lo_id;
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/wines/logins/" + loId
      );
      let rows_wi = response.data.data;
      // contenances (nombre de boutilles totale)
      for (let iwi = 0; iwi < rows_wi.length; iwi++) {
        const wi = rows_wi[iwi];
        wi.nbBouteille = 0;
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              //wi.nbBouteille += parseInt(tab2[1]);
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      // si on a une order on remplis les nombre de macaron par vin indiqué dans les orderlines
      if (this.or_id) {
        for (let iwi = 0; iwi < rows_wi.length; iwi++) {
          const row_wi = rows_wi[iwi];
          for (let iorl = 0; iorl < this.row_or.orderlines.length; iorl++) {
            const row_orl = this.row_or.orderlines[iorl];
            if (row_wi.wi_id === row_orl.wine.wi_id)
              row_wi.nbMacaron = row_orl.orl_quantity;
          }
        }
      }

      this.wines = rows_wi;
    },
    /*     editCandidatJure() {
      this.candidatJureWinEdit = true;
    }, */
    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "or_sensenroulement", text: "Sens d'enroulement" },
        { field: "or_mandrin", text: "Mandrin" },
        { field: "or_society_liv", text: "Société de livraison" },
        { field: "or_civility_liv", text: "Civilité de livraison" },
        { field: "or_name_liv", text: "Nom de livraison" },
        { field: "or_firstname_liv", text: "Prénom de livraison" },
        { field: "or_address1_liv", text: "Adresse de livraison" },
        { field: "or_zip_liv", text: "Code postal de livraison" },
        { field: "or_city_liv", text: "Ville de livraison" },
        { field: "or_society_fac", text: "Société de facturation" },
        { field: "or_address1_fac", text: "Adresse de facturation" },
        { field: "or_zip_fac", text: "Code postal de facturation" },
        { field: "or_city_fac", text: "Ville de facturation" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_or[field.field]) err.push(field);
      }
      if (
        !this.row_or.or_or &&
        !this.row_or.or_argent &&
        !this.row_or.or_excellence
      ) {
        err.push({
          text: "Vous devez selectonnez au moins un nombre de macarons"
        });
      }
      if (err.length) {
        this.dialogErrTxt = "";
        this.dialogErrTxt +=
          "<span class='fw-bold'>Les champs suivants sont obligatoires : </span><br>";
        if (!this.loSeleted) {
          this.dialogErrTxt +=
            "<span class='mb-1'>- Vous devez selectonnez un participant</span><br>";
        }
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += "- " + error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    async saveWin() {
      // this.row_or.wines = this.wines;
      this.row_or.wines = [];
      for (let iW = 0; iW < this.wines.length; iW++) {
        const row_wi = this.wines[iW];
        this.row_or.wines.push({
          wi_id: row_wi.wi_id,
          nbMacaron: row_wi.nbMacaron
        });
      }
      this.row_or.or_fraisport = this.fraisDePort;
      this.row_or.year = this.$store.state.year;
      this.row_or.lo_id = this.row_or.lo_id
        ? this.row_or.lo_id
        : this.loSeleted.lo_id;
      let response;
      if (this.row_or.or_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/backoffice/1.0/orders/" +
            this.or_id +
            "/step1",
          this.row_or
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/orders/step1",
          this.row_or
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.$emit("input", false);
      this.$emit("WinEditActions", {
        action: "saved",
        data: response.data.data
      });
    },

    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "canceled" });
    }
    /*     deleteConfirmWin() {
      this.$refs.formWine.deleteConfirmWin();
    }, */

    /*     WinEditCandidatJureActions(data) {
      if (data.action === "saved") {
        this.row_pa2 = data.row_pa;
      }
    } */
  }
};
</script>

<style scoped lang="scss">
.img-macaron {
  width: 80px;
}
</style>
