<template>
  <section class="">
    <!-- <div class="frame-img-top"></div> -->
    <div class="row mb-3">
      <div class="col-md-12">
        <h3 class="bis d-flex align-items-center">
          <div
            class="icon-title d-flex align-items-center justify-content-center"
          >
            <icon width="30" height="30" name="home"></icon>
          </div>
          <div>
            <div class="d-flex">
              <div>
                Tableau de bord du concours
              </div>
              <m-form-select
                :name="$Utils.randomstring('items_years')"
                :items="$store.state.items_years"
                style="width:110px"
                class="ms-2 input-year"
                :clearable="false"
                v-model="year"
              ></m-form-select>
            </div>
            <div class="fs-6 fw-light"></div>
          </div>
        </h3>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de candidats</div>
            <div class="stat-subtext">du concours {{ year }}</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.candidats.color">
            {{ $store.state.stats.candidats.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.candidats.nb }}</div>
      </div>
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de vins</div>
            <div class="stat-subtext">validés en {{ $store.state.year }}</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.wines.color">
            {{ $store.state.stats.wines.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.wines.nb }}</div>
      </div>
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Chiffre d'affaires</div>
            <div class="stat-subtext">du concours {{ year }}</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.ca.color">
            {{ $store.state.stats.ca.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ ($store.state.stats.ca.nb / 100) | formatCa }}
        </div>
      </div>
      <div class="frame w-100  ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de jurés</div>
            <div class="stat-subtext">validés en {{ year }}</div>
          </div>
          <div class="stat-percent " :class="$store.state.stats.jures.color">
            {{ $store.state.stats.jures.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.jures.nb }}</div>
      </div>
    </div>
    <div class="row mt-5">
      <div v-if="$Utils.hasRight('canUsersAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/admins')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="bolt"></icon>
            <h3 class="mb-0 ms-3">Les administrateurs</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canContactsAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/candidats')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="user-friends"></icon>
            <h3 class="mb-0 ms-3">Les candidats</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canContactsAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/wines')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="wine-bottle"></icon>
            <h3 class="mb-0 ms-3">Les vins</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canContactsAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/jures')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="wine-glass-alt"></icon>
            <h3 class="mb-0 ms-3">Les jurés</h3>
          </div>
        </div>
      </div>

      <div v-if="$Utils.hasRight('canFacturesAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/invoices')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="pen-fancy"></icon>
            <h3 class="mb-0 ms-3">Les Factures et avoirs</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canMacaronsAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/orders')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="shopping-cart"></icon>
            <h3 class="mb-0 ms-3">Les commandes</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canMailinglistAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/mailinglists')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="mail-bulk"></icon>
            <h3 class="mb-0 ms-3">Les mailinglistes</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canPresseAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/actualites')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="newspaper"></icon>
            <h3 class="mb-0 ms-3">L'espace presse</h3>
          </div>
        </div>
      </div>

      <div v-if="$Utils.hasRight('canPreferencesAccess')" class="col-md-5 pe-5">
        <div class="frame bg-primary pointer" @click="GoPage('/preferences')">
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="cog"></icon>
            <h3 class="mb-0 ms-3">Préférences</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "home",
  components: {},
  data() {
    return {
      // stats: { candidats: {}, wines: {}, jures: {} },
      year: this.$store.state.year
    };
  },
  mounted() {
    // this.$store.dispatch("loadStats");
    // this.$store.dispatch("loadPreferences");
  },
  watch: {
    year: async function(val) {
      await this.$store.dispatch("set_year", val);
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
      // this.loadStats();
    }
  },
  methods: {
    // async loadStats() {
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/backoffice/1.0/homestats",
    //     { params: { year: this.year } }
    //   );
    //   this.stats = response.data.data;
    //   // console.log("this.stats", this.stats);
    //   // calcul pourcentage
    //   //// nb candidat
    //   this.stats.candidats.diff = Math.round(
    //     ((this.stats.candidats.nb - this.stats.candidats.nbMoinsN) * 100) /
    //       this.stats.candidats.nbMoinsN
    //   );
    //   this.stats.candidats.color = "bg-warning";
    //   if (this.stats.candidats.diff > 5)
    //     this.stats.candidats.color = "bg-success";
    //   if (this.stats.candidats.diff < -5)
    //     this.stats.candidats.color = "bg-danger";
    //   //// nbJure
    //   this.stats.jures.diff = Math.round(
    //     ((this.stats.jures.nb - this.stats.jures.nbMoinsN) * 100) /
    //       this.stats.jures.nbMoinsN
    //   );
    //   this.stats.jures.color = "bg-warning";
    //   if (this.stats.jures.diff > 5) this.stats.jures.color = "bg-success";
    //   if (this.stats.jures.diff < -5) this.stats.jures.color = "bg-danger";
    //   //// nb vins
    //   this.stats.wines.diff = Math.round(
    //     ((this.stats.wines.nb - this.stats.wines.nbMoinsN) * 100) /
    //       this.stats.wines.nbMoinsN
    //   );

    //   this.stats.wines.color = "bg-warning";
    //   if (this.stats.wines.diff > 5) this.stats.wines.color = "bg-success";
    //   if (this.stats.wines.diff < -5) this.stats.wines.color = "bg-danger";
    // },
    GoPage(path) {
      this.$router.push(path).catch(err => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.frame-img-top {
  background-image: url(/images/plage-kai-pilger.jpeg);
  background-size: cover;
  height: 300px;
  background-position: center;
  margin: -20px -20px 20px -20px;
  border-radius: 6px 6px 0 0;
}
</style>
