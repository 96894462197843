<template>
  <div>
    <div class="frame px-5 bg-gray">
      <div class="row">
        <div class="col-md-12 d-flex ">
          <div>
            <h4>
              Les données
            </h4>
          </div>
          <div v-if="canReturnStep1">
            <button @click="emitStep1" class="btn btn-primary btn-sm ms-4">
              Modifier
            </button>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <h5>Adresse de livraison</h5>
              <div>{{ row_or.or_society_liv }}</div>
              <div>
                {{
                  $options.filters.formatContactNameSimple(
                    row_or,
                    "or_",
                    true,
                    "_liv"
                  )
                }}
              </div>
              <div
                v-html="
                  $options.filters.formatAddress(
                    row_or,
                    'or_',
                    false,
                    false,
                    '_liv'
                  )
                "
              ></div>
              <div
                v-if="row_or.login"
                v-html="$options.filters.formatEmail(row_or.login.lo_login)"
              ></div>
            </div>

            <div class="col-md-6">
              <h5>Adresse de facturation</h5>
              <div>{{ row_or.or_society_fac }}</div>
              <div
                v-html="
                  $options.filters.formatAddress(
                    row_or,
                    'or_',
                    false,
                    false,
                    '_fac'
                  )
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h5>Commande</h5>
          <div>
            Date de la commande :
            {{ row_or.or_date | formatDate }}
          </div>
          <div>
            Enroulement :
            {{
              $options.filters.formatvalue(
                row_or.or_interieurexterieur,
                "items_interieurexterieur",
                "uppercase",
                "singular"
              )
            }}
          </div>
          <div>
            Sens d'enroulement :
            {{
              $options.filters.formatvalue(
                row_or.or_sensenroulement,
                "items_sensenroulement",
                "uppercase",
                "singular"
              )
            }}
          </div>
          <div>Mandrin : {{ row_or.or_mandrin }}mm</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ReadOrderMacaron",
  components: {},
  props: {
    row_or: Object,
    // row_pa: Object,
    canReturnStep1: Boolean,
    keyload: Number
  },
  data() {
    return {};
  },
  watch: {
    keyload: function(v) {}
  },
  async mounted() {},
  methods: {
    /*     emitCandidat() {
      this.$emit("editCandidatJure");
    }, */
    emitStep1() {
      this.$emit("emitStep1");
    }
  }
};
</script>

<style lang="scss" scoped></style>
