<template>
  <div>
    <m-list-simple
      tableClass="table-hover table-striped"
      ref="userslist"
      :dynamic="true"
      :items="users"
      item-value="us_id"
      :item-height="30"
      :limit="users_limit"
      :skip="users_skip"
      :total="users_total"
      :pagging="pagging"
      :nbPage="nbPage"
      :nbPerPage="nbPerPage"
      :currentPage="currentPage"
      :cursorOnRows="true"
      :urlExportCSV="
        `${this.$config.server_url}/backoffice/1.0/users/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
      "
      @itemclick="editUser"
      @changePage="changePage"
    >
      <template v-slot:ths="{}">
        <th style="min-width:76px;">
          <span class="pointer">Avatar</span>
        </th>
        <th
          style="min-width:200px;"
          @click="sortUsers(['us_name', 'us_firstname'])"
        >
          <span class="pointer">Nom</span>
        </th>
        <th @click="sortUsers(['us_society'])">
          <span class="pointer">Société</span>
        </th>
        <th style="min-width:130px;" @click="sortUsers(['us_type'])">
          <span class="pointer">Droit</span>
        </th>
        <th style="min-width:130px;" @click="sortUsers(['us_connexion_date'])">
          <span class="pointer">Dernière connexion</span>
        </th>
        <th style="min-width:130px;" @click="sortUsers(['us_active'])">
          <span class="pointer">Statut</span>
        </th>
      </template>
      <template v-slot:tds="{ item }">
        <td>
          <div
            class="container-avatar  container-avatar-app"
            ref="avatar"
            :style="
              `background-image: url(${$config.server_url}/backoffice/1.0/users/${item.us_id}/avatar?token=${$store.state.accesstoken}&origin=${$config.backoffice_url}&c=${reloadkey_avatar_list})`
            "
          ></div>
        </td>
        <td>
          {{ $options.filters.formatContactNameSimple(item) }}
          <div class="small-text">
            Email :
            <a :href="'mailto:' + item.us_email"> {{ item.us_email }}</a>
          </div>
        </td>
        <td>
          {{ item.us_society }}
        </td>
        <td>
          {{ item | formatContactType }}
        </td>
        <td>
          {{
            item.signin_lastdate &&
            item.signin_lastdate.ac_date !== "0000-00-00 00:00:00"
              ? $dayjs(item.signin_lastdate.ac_date).format(
                  "DD/MM/YYYY [à] HH[H]mm"
                )
              : " - "
          }}
        </td>
        <td>
          <span :class="item.us_active ? 'color-green' : 'color-red'"
            >{{ item.us_active ? "Actif" : "Non-actif" }}<br
          /></span>
        </td>
      </template>
    </m-list-simple>
    <user-win-edit
      v-model="userWinEdit"
      :userType="usersType"
      :us_id="userSelected.us_id"
      @saved="userWinEditAction"
      @deleted="userWinEditAction"
      @canceled="userWinEditAction"
    >
    </user-win-edit>
  </div>
</template>
<script>
export default {
  name: "user-list",
  components: {},
  props: {
    filterText: String,
    loadUsersGo: Number,
    showNoActive: Boolean
  },
  data() {
    return {
      usersType: this.$route.meta.type,
      users_limit: 50,
      users_skip: 0,
      users_total: 0,
      users: [],
      reloadkey_avatar_list: 0,
      userWinEdit: false,
      userSelected: {},
      sortOrder: "asc",
      sortFields: ["us_name", "us_firstname"],
      nbPage: 0,
      nbPerPage: 10,
      pagging: true,
      currentPage: 1
    };
  },
  async mounted() {
    if (this.$route.name == "adminsedit") {
      this.editUser({ us_id: this.$route.params.id * 1 });
    }

    if (this.$route.params.page) {
      this.currentPage = parseInt(this.$route.params.page);
      this.users_skip = (this.currentPage - 1) * this.nbPerPage;
    }
    //this.loadUsers(this.users_skip);
  },
  watch: {
    $route(to, from) {
      this.usersType = this.$route.meta.type;
      /* if (this.$route.params.page) {
        this.currentPage = parseInt(this.$route.params.page);
        this.users_skip = (this.currentPage - 1) * this.nbPerPage;
      } */
    },
    filterText() {
      if (this.delayFilterText) window.clearTimeout(this.delayFilterText);
      this.delayFilterText = window.setTimeout(() => {
        this.loadUsers(0);
      }, 300);
    },
    loadUsersGo(v) {
      this.loadUsers(this.users_skip);
    }
  },
  methods: {
    sortUsers(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "us_connexion_date") {
        this.changePage(1);
      } else {
        this.users.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (
              (!a.signin_lastdate || !a.signin_lastdate.ac_date) &&
              (b.signin_lastdate || b.signin_lastdate.ac_date)
            )
              return -1;
            if (
              (a.signin_lastdate || a.signin_lastdate.ac_date) &&
              (!b.signin_lastdate || !b.signin_lastdate.ac_date)
            )
              return 1;
            if (
              a.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isBefore(
                b.signin_lastdate.ac_date
              )
            )
              return -1;
            if (
              b.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isAfter(
                b.signin_lastdate.ac_date
              )
            )
              return 1;
            if (
              (a.signin_lastdate.ac_date === "0000-00-00" &&
                b.signin_lastdate.ac_date === "0000-00-00") ||
              this.$dayjs(a.signin_lastdate.ac_date)
                .startOf("day")
                .isSame(b.signin_lastdate.ac_date)
            )
              return 0;
          } else {
            if (
              (a.signin_lastdate || a.signin_lastdate.ac_date) &&
              (!b.signin_lastdate || !b.signin_lastdate.ac_date)
            )
              return -1;
            if (
              (!a.signin_lastdate || !a.signin_lastdate.ac_date) &&
              (b.signin_lastdate || b.signin_lastdate.ac_date)
            )
              return 1;
            if (
              a.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isBefore(
                b.signin_lastdate.ac_date
              )
            )
              return 1;
            if (
              b.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isAfter(
                b.signin_lastdate.ac_date
              )
            )
              return -1;
            if (
              (a.signin_lastdate.ac_date === "0000-00-00" &&
                b.signin_lastdate.ac_date === "0000-00-00") ||
              this.$dayjs(a.signin_lastdate.ac_date)
                .startOf("day")
                .isSame(b.signin_lastdate.ac_date)
            )
              return 0;
          }
        });
      }
    },

    async loadUsers(skip) {
      this.reloadkey_avatar_list++;
      let types = this.$store.state.usersTypes[this.usersType].types;
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        types: types,
        skip: skip,
        limit: this.pagging ? this.nbPerPage : this.users_limit,
        // archive: this.showNoActive,
        showall: true,
        sort: sort,
        signindate: true
      };
      this.cancelAxiosLoadUsers && this.cancelAxiosLoadUsers();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/users",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadUsers = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.users = response.data.data;
      this.users_total = response.data.meta.total;
      this.nbPage = Math.ceil(this.users_total / this.nbPerPage);
      this.users_skip = skip;
      if (this.$refs.userslist) {
        // this.$refs.userslist.update();
        // if (skip == 0) this.$refs.userslist.scrollToTop();
      }
      this.$emit("userLoaded", this.users_total);
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.$router
        .push("/" + this.usersType + "/page/" + this.currentPage)
        .catch(err => {});
      let newSkip = (currentPage - 1) * this.nbPerPage;
      this.loadUsers(newSkip);
    },
    editUser(row_us) {
      this.userSelected = row_us;
      this.$router
        .push("/" + this.usersType + "/" + row_us.us_id + "/edit")
        .catch(err => {});
      this.userWinEdit = true;
    },
    userWinEditAction(us_id) {
      this.$router.push("/" + this.usersType).catch(err => {});
      //this.loadUsers(0);
      this.changePage(this.currentPage);
    }
  }
};
</script>
<style lang="scss" scoped>
.color-green {
  color: #52c131;
}
.color-red {
  color: red;
}
</style>
