import Vue from "vue";
import moment from "moment";
//import dayjs from "dayjs";
import _ from "lodash";
import store from "./store";
// import axios from "axios";
// import config from "../config";
var numeral = require("numeral");
numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€"
  }
});
numeral.locale("fr");

Vue.filter("lowerCase", function(val) {
  return val.toLowerCase();
});
Vue.filter("formatPurcent", function(val) {
  return numeral((val * 1) / 100).format("0,0 %") + "";
});
Vue.filter("formatFromTo", function(valFrom, valTo) {
  let mF = moment(new Date(valFrom));
  let mT = moment(new Date(valTo));
  return "De " + mF.format("HH[H]mm") + " à " + mT.format("HH[H]mm");
});
Vue.filter("formatDate", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.format("DD/MM/YYYY");
  }
  if (moment.isMoment(val)) v = val.format("DD/MM/YYYY");
  else if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY");
  return v;
});
Vue.filter("formatDateTime", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.format("DD/MM/YYYY [à] HH[H]mm");
  }
  if (moment.isMoment(val)) v = val.format("DD/MM/YYYY [à] HH[H]mm");
  if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY [à] HH[H]mm");
  return v;
});
Vue.filter("formatDateAgo", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.fromNow();
  }
  if (moment.isMoment(val)) v = val.fromNow();
  if (moment.isDate(val)) v = moment(val).fromNow();
  return v;
});
Vue.filter("formatPrice", function(val, suffixe) {
  if (!suffixe) suffixe = " €";
  val = val * 1;
  // if (val >= 1000000)
  //   return numeral(val / 1000000).format("0,0.0") + "M" + suffixe;
  // if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "K" + suffixe;
  return numeral(val).format("0,0") + suffixe;
});
Vue.filter("formatPriceDivisePar100", function(val, suffixe) {
  if (!suffixe) suffixe = " €";
  val = val * 1;
  val = val / 100;
  return numeral(val).format("0,0.00") + suffixe;
});

Vue.filter("formatCa", function(val) {
  val = val * 1;
  return numeral(val).format("0,0[.]00 $") + " HT";
});
Vue.filter("formatChiffreSimple", function(val) {
  val = val * 1;
  return numeral(val).format("0,0");
});
Vue.filter("formatYesNoInt", function(val) {
  if (!val) return "?";
  return val === 1 ? "Oui" : "Non";
});
Vue.filter("formatYesNo", function(val) {
  return val ? "Oui" : "Non";
});
Vue.filter("formatYesNoColored", function(val) {
  return val
    ? "<span class='text-success'>Oui</span>"
    : "<span class='text-danger'>Non</span>";
});
Vue.filter("formatFromArray", function(val, myarray, mydefault) {
  let f = _.result(_.find(myarray, { value: val }), "text", mydefault);
  return f;
});

Vue.filter("contactIcon", function(row_co) {
  let ico = "mdi-account";
  if (row_co.co_type !== "contact") ico = "mdi-account-card-details";
  else if (row_co.co_issyndic) ico = "mdi-crane";
  else if (row_co.co_isrenter) ico = "mdi-owl";
  else if (row_co.co_isowner) ico = "mdi-castle";
  else if (row_co.co_isagency) ico = "mdi-home-thermometer";
  else if (row_co.co_isagent) ico = "mdi-account-key";
  else if (row_co.co_issociety) ico = "mdi-domain";
  return ico;
});

function formatName(contact, prefixe, suffixe = "") {
  return (
    contact[prefixe + "name" + suffixe].toUpperCase() +
    " " +
    contact[prefixe + "firstname" + suffixe].charAt(0).toUpperCase() +
    contact[prefixe + "firstname" + suffixe].substr(1).toLowerCase()
  );
}

Vue.filter("formatContactSociety", function(contact, prefixe = "us_") {
  if (!contact || !contact[prefixe + "name"]) return "";
  let MMme = contact[prefixe + "civility"]
    ? contact[prefixe + "civility"] + " "
    : "";
  return (
    contact[prefixe + "society"] +
    " (" +
    MMme +
    formatName(contact, prefixe) +
    " )"
  );
});
Vue.filter("formatContactNameSimple", function(
  contact,
  prefixe = "us_",
  civility = false,
  suffixe = ""
) {
  if (!contact || !contact[prefixe + "name" + suffixe]) return "";
  let MMme =
    civility && contact[prefixe + "civility" + suffixe]
      ? contact[prefixe + "civility" + suffixe] + " "
      : "";
  return MMme + formatName(contact, prefixe, suffixe);
});
Vue.filter("formatParticipationLogin", function(contact) {
  // console.log("contact", contact);
  if (!contact) return "";
  return (
    contact.pa_society +
    " (" +
    contact.lo_name +
    " " +
    contact.lo_firstname +
    ")"
  );
});
Vue.filter("formatContactNameSimpleEmail", function(contact, prefixe = "us_") {
  //console.log("contact", contact);
  if (!contact || !contact[prefixe + "name"]) return "";
  let email =
    contact.login && contact.login.lo_login
      ? contact.login.lo_login
      : contact.lo_login
      ? contact.lo_login
      : contact[prefixe + "email"];
  return formatName(contact, prefixe) + " (" + email + ")";
});

Vue.filter("formatOrderState", function(state) {
  if (state == "CREATING") return "En création";
  if (state == "SENDED") return "Envoyée";
  if (state == "PAYED") return "À expédier";
  if (state == "RECEIVED") return "Réceptionné";
  return "";
});
Vue.filter("EmailLogin", function(contact) {
  if (!contact || !contact.lo_name) return "";
  let name = formatName(contact, "lo_");
  return name + " (" + contact.lo_login + ")";
});
Vue.filter("formatContactName", function(
  row_co,
  useBold = false,
  blackSociety = false
) {
  if (_.isPlainObject(useBold)) useBold = false;
  if (!row_co) return "";
  let txt = [];
  if (row_co.co_name)
    txt.push(
      (useBold ? "<b>" : "") +
        row_co.co_name.toUpperCase() +
        (useBold ? "</b>" : "")
    );
  if (row_co.co_firstname) txt.push(row_co.co_firstname);
  row_co.completeName = txt.join(" ");
  let res = row_co.completeName;
  if (
    (row_co.co_issociety || row_co.co_isagency || row_co.co_issyndic) &&
    blackSociety
  ) {
    res = `<span class='span-society'>${res}</span>`;
  }
  if (row_co.co_type != "contact" && blackSociety) {
    res = `<span class='span-user'>${res}</span>`;
  }
  return res;
});
Vue.filter("formatContactType", function(row_us) {
  if (!row_us) return "";
  return _.result(
    _.find(store.state.items_rights_roles, { riro_id: row_us.us_type }),
    "riro_label",
    "-"
  );
});

Vue.filter("nl2br", function(str) {
  let breakTag = "<br />";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
});

Vue.filter("formatWineMillesime", function(key) {
  if (!key) return "";
  let text = key;
  if (key * 1 == 100000) text = "Non millésimé";
  return text;
});

Vue.filter("formatWineName", function(row_wi) {
  let text = row_wi.wi_name;
  if (row_wi.wi_name2) text += " | " + row_wi.wi_name2;
  if (row_wi.wi_name3) text += " | " + row_wi.wi_name3;
  return text;
});

Vue.filter("formatWineColor", function(key) {
  if (!key) return "";
  let text;
  text = _.result(
    _.find(store.state.items_winesColors, { key: key }),
    "text",
    "erreur"
  );
  return text;
});

Vue.filter("formatvalue", function(value, typeToFindIn, lowercase, plurial) {
  if (!store.state[typeToFindIn]) return "";
  if (!value) return "-";
  let text;
  text = _.result(
    _.find(store.state[typeToFindIn], { value: value }),
    "text",
    value
  );
  if (typeToFindIn === "items_medailles" && value === 3) text = "Bronze";
  if (lowercase === "lowercase") {
    text = text.toLowerCase();
  }
  if (plurial === "plurial") {
    text = text + "s";
  }
  return text;
});

Vue.filter("formatActionName", function(row_ac) {
  if (!row_ac) return "";
  let txt = [];
  if (row_ac.createdAt)
    txt.push(moment(row_ac.createdAt).format("DD/MM/YYYY [à] HH[H]mm"));
  //if (row_co) txt.push(Vue.filter("formatContactName")(row_co.data.data));
  if (row_ac.ac_comment)
    txt.push(
      row_ac.ac_comment.slice(
        0,
        row_ac.ac_comment.length > 40 ? row_ac.ac_comment.length : 40
      ) + "..."
    );
  return txt.join(" / ");
});

Vue.filter("formatEmail", function(txt) {
  return `<a href="mailto:${txt}">${txt}</a>`;
});
Vue.filter("formatEventName", function(row_ve) {
  // console.log("row_ve", row_ve);
  let txt = "";
  txt +=
    "Du " +
    moment(row_ve.ve_dtstart).format("DD/MM/YYYY [à] HH[H]mm") +
    " au " +
    moment(row_ve.ve_dtend).format("DD/MM/YYYY [à] HH[H]mm") +
    " : " +
    row_ve.ve_summary;
  return txt;
});

Vue.filter("formatTel", function(txt) {
  return `<a href="tel:${txt}">${txt}</a>`;
});
Vue.filter("formatWeb", function(txt) {
  return `<a href="${txt}" target="_blank">${txt}</a>`;
});

Vue.filter("formatAddress", function(
  item,
  prefix,
  sep = false,
  linkMap = false,
  suffixe = ""
) {
  let fac = suffixe ? suffixe : "";
  if (!sep) sep = "<br>";
  let txt = [];
  if (item[prefix + "address1" + fac])
    txt.push(item[prefix + "address1" + fac]);
  if (item[prefix + "address2" + fac])
    txt.push(item[prefix + "address2" + fac]);
  if (item[prefix + "address3" + fac])
    txt.push(item[prefix + "address3" + fac]);
  if (item[prefix + "zip" + fac] && item[prefix + "city" + fac])
    txt.push(item[prefix + "zip" + fac] + " " + item[prefix + "city" + fac]);
  if (item[prefix + "country" + fac]) txt.push(item[prefix + "country" + fac]);
  if (linkMap)
    return `<a href="https://www.google.fr/maps?q=${encodeURI(
      txt.join(", ")
    )}" target="_blank">${txt.join(sep)}</a>`;
  else return txt.join(sep);
});

Vue.filter("plural", function(nb, singular, plural) {
  let res = nb + "";
  if (!singular) return res;
  else if (nb > 1 && plural && plural.indexOf("%n") >= 0)
    res = plural.replace(/%n/g, nb + "");
  else if (nb > 1 && plural && plural.indexOf("%n") < 0)
    res = nb + " " + plural;
  else if (nb > 1 && !plural) res = nb + " " + singular + "s";
  else if (nb <= 1 && singular.indexOf("%n") >= 0)
    res = singular.replace(/%n/g, nb + "");
  else if (nb <= 1 && singular.indexOf("%n") < 0) res = nb + " " + singular;
  return res;
});
