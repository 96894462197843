<template>
  <section>
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div
          class="icon-title d-flex align-items-center justify-content-center"
        >
          <icon width="30" height="30" name="cog"></icon>
        </div>
        <div>
          <div>Préférences</div>
          <div class="fs-6 fw-light">
            Gérez les préférences du programme et du concours
          </div>
        </div>
      </h3>
    </div>
    <div class="row">
      <div v-if="$Utils.hasRight('canPreferencesAccess')" class="col-md-5 pe-5">
        <div
          class="frame bg-primary pointer "
          @click="GoPage('preferences/yearpreferences')"
        >
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="calendar-alt"></icon>
            <h3 class="mb-0 ms-3">Préférences par année</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canRightsAccess')" class="col-md-5 pe-5">
        <div
          class="frame bg-primary pointer"
          @click="GoPage('preferences/rights')"
        >
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="key"></icon>
            <h3 class="mb-0 ms-3">Tous les droits</h3>
          </div>
        </div>
      </div>
      <div v-if="$Utils.hasRight('canLogsAccess')" class="col-md-5 pe-5">
        <div
          class="frame bg-primary pointer"
          @click="GoPage('preferences/logs')"
        >
          <div class="d-flex align-items-center">
            <icon width="40" height="40" name="list"></icon>
            <h3 class="mb-0  ms-3">Tous les logs</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import PreferencesRights from "./PreferencesRights.vue";
//import PreferencesTrads from "./PreferencesTrads.vue";
//import PreferencesShared from "./PreferencesShared.vue";
export default {
  name: "preferences",
  components: {},
  data() {
    return {};
  },
  mounted() {
    if (!this.$Utils.hasRight("canPreferencesAccess")) {
      this.$router.push("/");
    }
  },
  watch: {},
  methods: {
    GoPage(path) {
      this.$router.push(path).catch(err => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
