var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"frame px-5 bg-gray"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 d-flex "},[_vm._m(0),(_vm.canReturnStep1)?_c('div',[_c('button',{staticClass:"btn btn-primary btn-sm ms-4",on:{"click":_vm.emitStep1}},[_vm._v(" Modifier ")])]):_vm._e()])]),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Adresse de livraison")]),_c('div',[_vm._v(_vm._s(_vm.row_or.or_society_liv))]),_c('div',[_vm._v(" "+_vm._s(_vm.$options.filters.formatContactNameSimple( _vm.row_or, "or_", true, "_liv" ))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.formatAddress(
                  _vm.row_or,
                  'or_',
                  false,
                  false,
                  '_liv'
                )
              )}}),(_vm.row_or.login)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatEmail(_vm.row_or.login.lo_login))}}):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Adresse de facturation")]),_c('div',[_vm._v(_vm._s(_vm.row_or.or_society_fac))]),_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.formatAddress(
                  _vm.row_or,
                  'or_',
                  false,
                  false,
                  '_fac'
                )
              )}})])])]),_c('div',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Commande")]),_c('div',[_vm._v(" Date de la commande : "+_vm._s(_vm._f("formatDate")(_vm.row_or.or_date))+" ")]),_c('div',[_vm._v(" Enroulement : "+_vm._s(_vm.$options.filters.formatvalue( _vm.row_or.or_interieurexterieur, "items_interieurexterieur", "uppercase", "singular" ))+" ")]),_c('div',[_vm._v(" Sens d'enroulement : "+_vm._s(_vm.$options.filters.formatvalue( _vm.row_or.or_sensenroulement, "items_sensenroulement", "uppercase", "singular" ))+" ")]),_c('div',[_vm._v("Mandrin : "+_vm._s(_vm.row_or.or_mandrin)+"mm")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v(" Les données ")])])}]

export { render, staticRenderFns }