<template>
  <div class="container-fluid">
    <div class="logo-absolute text-center mb-4">
      <img
        class="img-fluid"
        :src="`${$config.server_url}/backoffice/1.0/logo`"
        alt="logo"
      />
    </div>

    <div class="row">
      <div
        class="col-md-4 offset-md-4 container-form-login d-flex flex-column justify-content-center"
      >
        <div class="w-100">
          <div class="d-flex mb-3">
            <div class="d-flex flex-column">
              <h1 class="mb-0">Se connecter</h1>
            </div>
          </div>
          <div ref="part1" v-show="currentPart == 'part1'">
            <m-form-text
              label="Identifiant"
              label-position="top"
              v-model="co_email"
              name="login"
              autocomplete
            ></m-form-text>
            <div class="relative">
              <m-form-text
                :type="showPassword ? 'text' : 'password'"
                label="Mot de passe"
                label-position="top"
                v-model="co_password"
                autocomplete
                name="password"
              ></m-form-text>
              <div
                class="icon-eyes pointer"
                @click="showPassword = !showPassword"
              >
                <icon v-if="!showPassword" name="eye"></icon>
                <icon v-if="showPassword" name="eye-slash"></icon>
              </div>
            </div>
            <div class="alert alert-danger" ref="alert" v-if="alert_txt">
              {{ alert_txt }}
            </div>
            <div class="d-flex justify-content-end">
              <a class="pointer " @click="currentPart = 'part2'"
                >Mot de passe oublié</a
              >
            </div>
            <div>
              <button @click="login()" class=" btn  btn-primary ">
                Se connecter
              </button>
            </div>
          </div>

          <div ref="part2" v-show="currentPart == 'part2'">
            <m-form-text
              label="Identifiant ou Email"
              label-position="top"
              v-model="co_email"
              name="login"
              autocomplete
            ></m-form-text>
            <div ref="alert" class="alert alert-danger" v-if="alert_txt_part2">
              {{ alert_txt_part2 }}
            </div>
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-secondary mt-3"
              >
                Annuler
              </button>
              <button @click="sendPassword" class="btn btn-primary ms-2 mt-3">
                Envoyer le mot de passe
              </button>
            </div>
          </div>

          <div ref="part3" v-show="currentPart == 'part3'">
            Nous venons de vous envoyer un email vous permettant de mettre à
            jour votre mot de passe. <br />
            La réception peut prendre parfois quelques minutes. <br />
            En cas de non réception, vérifiez dans votre boite SPAMS. <br />
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-primary mt-3"
              >
                Se connecter maintenant
              </button>
            </div>
          </div>

          <div ref="part4" v-show="currentPart == 'part4'">
            <div
              class="alert alert-danger mt-2 mb-0"
              ref="alert"
              v-if="alert_txt_part4"
            >
              {{ alert_txt_part4 }}
            </div>
            <div>
              <m-form-password
                :showRules="true"
                valid="shadow"
                display="col"
                :rules="rulesPassword"
                :required="true"
                @validPassword="validPassword"
              ></m-form-password>
              <button @click="newpassword" class="ms-auto btn btn-primary mt-2">
                Redéfinir le nouveau mot de passe
              </button>
            </div>
          </div>

          <div ref="part5" v-show="currentPart == 'part5'">
            C'est ok !
            <br />Votre nouveau mot de passe a bien été enregistré. <br />
            Vous pouvez maintenant vous connecter.
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-primary mt-3"
              >
                Se connecter maintenant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "login",
  components: {},
  data() {
    return {
      currentPart: "part1",
      //societyName: "",
      logoUrl: "",
      co_email: "",
      co_password: "",
      showPassword: false,
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      isValidPassword: false,
      errPassword: [],
      alert_txt: "",
      alert_type: "warning",
      alert_txt_part2: "",
      alert_type_part2: "warning",
      alert_txt_part4: "",
      alert_type_part4: "warning",
      challenge_newpassword: "",
      co_id: ""
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
    //  this.$root.$children[0].hideMenus();
    //  this.$root.$children[0].resizeMainContainer();
    if (this.$route.name == "resetpass") {
      this.currentPart = "part4";
      this.checkchallenge(
        this.$route.params.us_id,
        this.$route.query.challenge
      );
    }
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Enter") {
        this.login();
      }
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.co_password1 = password;
    },

    async newpassword() {
      if (!this.challenge_newpassword) return;
      this.alert_txt_part4 = "";
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          this.alert_txt_part4 += this.errPassword[ierr] + " ";
        }
      }
      if (!this.alert_txt_part4) {
        let response = await this.$axios.post(
          this.$config.server_url +
            "/backoffice/1.0/signin/forgetpassword/newpassword",
          {
            us_password: this.co_password1,
            us_id: this.co_id,
            challenge: this.challenge_newpassword
          }
        );
        if (response.data.err) {
          this.alert_txt_part4 =
            "Une erreur est survenue, veuillez recommencer la procédure";
        } else {
          this.currentPart = "part5";
        }
      }
    },

    async checkchallenge(co_id, challenge) {
      // console.log("co_id, challenge", co_id, challenge);
      this.co_id = co_id;
      let response = await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/signin/forgetpassword/checkchallenge",
        { us_id: co_id, challenge }
      );
      if (response.data.err) {
        this.alert_txt_part4 =
          "Une erreur est survenue, veuillez recommencer la procédure";
      } else {
        // this.currentPart = "part3";
        this.challenge_newpassword = response.data.data.challenge;
      }
    },

    async sendPassword() {
      try {
        await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/forgetpassword",
          { us_email: this.co_email }
        );
      } catch (error) {
        console.warn("error", error);
        this.alert_txt_part2 =
          "Nous n'avons pas pu retrouver votre identifiant";
        return;
      }
      this.currentPart = "part3";
    },

    async login() {
      try {
        let response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/login",
          { us_email: this.co_email, us_password: this.co_password }
        );
        if (response.data.err) {
          this.alert_txt = "Erreur de connexion";
          this.alert_type = "warning";
          return;
        }
        this.alert_txt = "Vous êtes connecté";
        this.alert_type = "success";
        // this.$store.commit("set_token", response.data.accesstoken);
        // this.$store.commit("set_refreshtoken", response.data.refreshtoken);
        // this.$store.commit("set_user", response.data.data);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.accesstoken,
          refreshtoken: response.data.refreshtoken,
          user: response.data.data,
          preferences: response.data.preferences
        });
        //   this.$root.$children[0].showMenus();
        this.$router.push("/home").catch(err => {});
      } catch (error) {
        console.error("error", error);
        this.alert_txt = "Erreur!!!";
        this.alert_type = "warning";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-form-login {
  padding: 10px;
  margin: auto;
  background-color: #f4f4f4d1;
  border-radius: 10px;
}
.logo-absolute {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 300px;
}
</style>
