<template>
  <div>
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div
          class="icon-title d-flex align-items-center justify-content-center"
        >
          <icon width="30" height="30" name="cog"></icon>
        </div>
        <div>
          <div>Les droits</div>
          <div class="fs-6 fw-light">Gérez les droits des utilisateurs</div>
        </div>
      </h3>
    </div>
    <div class="row" v-if="acl">
      <div class="col-md-12">
        <div class="frame">
          <div class="hscroll">
            <table class="table table-striped ">
              <thead class="thead-light">
                <tr>
                  <th style="min-width:200px;"></th>
                  <th
                    @click="clickColumn(row_riro.riro_id)"
                    v-for="row_riro in items_rights_roles"
                    :key="row_riro.riro_id"
                  >
                    {{ row_riro.riro_label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row_rire in items_rights_resources"
                  :key="row_rire.rire_id"
                >
                  <td @click="clickLine(row_rire.rire_id)">
                    {{ row_rire.rire_label }}
                  </td>
                  <td
                    v-for="row_riro in items_rights_roles"
                    :key="row_riro.riro_id"
                  >
                    <div>
                      <input
                        type="checkbox"
                        v-model="acl[row_riro.riro_id][row_rire.rire_id]"
                        :name="`${row_riro.riro_id}_${row_rire.rire_id}`"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-3">
            Vous pouvez cliquer sur l'intitulé d'une ligne ou d'une colonne pour
            sélectionner toute la ligne ou toute la colonne.
          </p>
          <button class="btn btn-primary " @click="saveRight">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-message-dialog
      v-model="dialogSuccess"
      title="Confirmation"
      redirect="preferences"
      :text="dialogSuccessTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "rights",
  components: {},
  data() {
    let items_rights_roles = this.$store.state.items_rights_roles;
    let items_rights_rolesresources = this.$store.state
      .items_rights_rolesresources;
    let items_rights_resources = this.$store.state.items_rights_resources;
    let acl = {};
    for (let iRole = 0; iRole < items_rights_roles.length; iRole++) {
      const role = items_rights_roles[iRole];
      for (
        let iResources = 0;
        iResources < items_rights_resources.length;
        iResources++
      ) {
        const resource = items_rights_resources[iResources];
        let f = this.$_.find(items_rights_rolesresources, {
          riro_id: role.riro_id,
          rire_id: resource.rire_id
        });
        if (!acl[role.riro_id]) acl[role.riro_id] = {};
        acl[role.riro_id][resource.rire_id] = false;
        if (f) acl[role.riro_id][resource.rire_id] = true;
      }
    }
    return {
      limitdates: [
        { key: 1, val: "1 mois" },
        { key: 3, val: "3 mois" },
        { key: 6, val: "6 mois" },
        { key: 12, val: "1 an" },
        { key: 24, val: "2 ans" }
      ],
      limitdate: 12,
      user: this.$store.state.user,
      items_rights_resources,
      items_rights_roles,
      items_rights_rolesresources,
      acl,
      // items_rights_ressources: [],
      dialogSuccess: false,
      dialogSuccessTxt: ""
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canRightsAccess")) {
      this.$router.push("/");
    }
    // this.loadRight();
  },
  methods: {
    // loadRight() {
    //   this.user = this.$store.state.user;
    //   // this.items_rights_roles = this.$store.state.items_rights_roles.filter(
    //   //   item => {
    //   //     return item.riro_id;
    //   //   }
    //   // );
    //   // this.items_rights_ressources = this.$store.state.items_rights_ressources;
    //   this.items_rights_resources = this.$store.state.items_rights_resources.sort(
    //     (a, b) => {
    //       if (
    //         a.rire_id.indexOf("canSeeProduct_") >= 0 &&
    //         b.rire_id.indexOf("canSeeProduct_") >= 0
    //       )
    //         return a.rire_label.localeCompare(b.rire_label);
    //       if (a.rire_id.indexOf("canSeeProduct_") >= 0) return 1;
    //       if (b.rire_id.indexOf("canSeeProduct_") >= 0) return -1;
    //       return a.rire_label.localeCompare(b.rire_label);
    //     }
    //   );
    //   this.items_rights_roles = this.$store.state.items_rights_roles;
    //   this.items_rights_rolesresources = this.$store.state.items_rights_rolesresources;
    //   // console.log("this.items_rights_resources", this.items_rights_resources);
    //   // for (let iRe = 0; iRe < this.items_rights_resources.length; iRe++) {
    //   //   const resources = this.items_rights_resources[iRe];
    //   //   for (let i = 0; i < this.items_rights_roles.length; i++) {
    //   //     const role = this.items_rights_roles[i];
    //   //     resources[role.riro_id] = false;
    //   //   }
    //   // }
    //   // for (let index = 0; index < this.items_rights_resources.length; index++) {
    //   //   const resource = this.items_rights_resources[index];
    //   //   let index = this.$_.findIndex(this.items_rights_resources, function(o) {
    //   //     return o.rire_id === resource.rire_id;
    //   //   });
    //   //   this.items_rights_resources[index][resource.riro_id] = true;
    //   // }
    //   this.acl = {};
    //   for (let iRole = 0; iRole < this.items_rights_roles.length; iRole++) {
    //     const role = this.items_rights_roles[iRole];
    //     for (
    //       let iResources = 0;
    //       iResources < this.items_rights_resources.length;
    //       iResources++
    //     ) {
    //       const resource = this.items_rights_resources[iResources];
    //       let f = this.$_.find(this.items_rights_rolesresources, {
    //         riro_id: role.riro_id,
    //         rire_id: resource.rire_id
    //       });
    //       if (!this.acl[role.riro_id]) this.acl[role.riro_id] = {};
    //       this.acl[role.riro_id][resource.rire_id] = false;
    //       if (f) this.acl[role.riro_id][resource.rire_id] = true;
    //     }
    //   }

    //   // let response = await this.$axios.get(
    //   //   this.$config.server_url + "/backoffice/1.0/preferences/shared"
    //   // );
    //   // // console.log("response", response);
    //   // let shared = response.data.data;
    //   // this.limitdate = shared.limitdate ? shared.limitdate : 12;
    //   // console.log("this.acl", this.acl);
    // },
    istrue(el) {
      return el ? true : false;
    },
    rireIsTrue(el, rire_id) {
      return el[rire_id] ? true : false;
    },
    clickColumn(riro_id) {
      let that = this;
      // group by pour savoir si on doit mettre à true ou false en fonction du nombre de true ou false déjà enregistré
      let result = this.$_.groupBy(this.acl[riro_id], this.istrue);
      let newVal =
        (result.true > result.false || !result.true) && result.false.length > 0
          ? true
          : false;
      this.$_.forEach(this.acl[riro_id], function(value, key) {
        that.acl[riro_id][key] = newVal;
      });
      this.$forceUpdate();
    },
    clickLine(rire_id) {
      let that = this;
      let valtrue = 0;
      let valfalse = 0;
      // première boucle pour voir si on met à true ou false en fonction du nombre de true ou false déjà enregistré
      this.$_.forEach(this.acl, function(value, key) {
        if (that.acl[key][rire_id]) valtrue++;
        else valfalse++;
      });
      let newVal =
        (valtrue > valfalse || valtrue === 0) && valfalse > 0 ? true : false;
      this.$_.forEach(this.acl, function(value, key) {
        that.acl[key][rire_id] = newVal;
      });
      this.$forceUpdate();
    },
    async saveRight() {
      let response = await this.$axios.put(
        this.$config.server_url + "/backoffice/1.0/preferences/acl",
        {
          acl: this.acl,
          limitdate: this.limitdate
        }
      );

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.$store.commit("set_acl_rolesresources", response.data.data);
      /* this.dialogSuccess = true;
      this.dialogSuccessTxt = "Les droits ont bien été enregistrés"; */
      this.$store.dispatch("showToast", {
        title: "confirmation",
        text: "Les droits ont été bien enregistrés",
        color: "#2aaa2a"
      });
      // this.$emit("preferencesSaved", "gestionRights");
      // await this.$axios.put(
      //   this.$config.server_url + "/backoffice/1.0/preferences/shared/limitdate",
      //   { value: this.limitdate }
      // );
    }
  }
};
</script>

<style lang="scss" scoped>
/*************** table droits **************/
/* tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
} */
</style>
