<template>
  <section>
    <div
      class="frame"
      v-if="researchResponse.users && researchResponse.users.length"
    >
      <h4>{{ researchResponse.users.length }} utilisateur(s) trouvé(s)</h4>
      <m-list-simple
        tableClass="table-hover table-striped table-small"
        ref="userslist"
        :dynamic="true"
        :items="researchResponse.users"
        item-value="us_id"
        :item-height="30"
        :total="researchResponse.users.length"
        :cursorOnRows="true"
        @itemclick="goToResearch"
      >
        <template v-slot:ths="{}">
          <th style="min-width:200px;">
            <span class="pointer">Nom</span>
          </th>
          <th style="min-width:130px;">
            <span class="pointer">Droit</span>
          </th>
          <th style="min-width:130px;">
            <span class="pointer">Statut</span>
          </th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ $options.filters.formatContactNameSimple(item) }}
            <div class="small-text">
              Email :
              <a :href="'mailto:' + item.us_email"> {{ item.us_email }}</a>
            </div>
          </td>
          <td>
            {{ item.us_type }}
          </td>
          <td>{{ item.us_active ? "Actif" : "Non-actif" }}<br /></td>
        </template>
      </m-list-simple>
    </div>
    <div
      class="frame"
      v-if="researchResponse.xxxs && researchResponse.xxxs.length"
    >
      <h4>{{ researchResponse.xxxs.length }} xxx(s) trouvé(s)</h4>
      <m-list-simple
        tableClass="table-hover table-striped table-small"
        ref="xxxslist"
        :dynamic="true"
        :items="researchResponse.xxxs"
        item-value="xx_id"
        :item-height="30"
        :total="researchResponse.xxxs.length"
        :cursorOnRows="true"
        @itemclick="goToResearch"
      >
        <template v-slot:ths="{}">
          <th style="min-width:200px;">
            <span class="pointer">Nom</span>
          </th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ $options.filters.formatContactNameSimple(item) }}
          </td>
        </template>
      </m-list-simple>
    </div>
    <div
      class="frame"
      v-if="researchResponse.emails && researchResponse.emails.length"
    >
      <h4>{{ researchResponse.emails.length }} email(s) trouvé(s)</h4>
      <m-list-simple
        tableClass="table-hover table-striped table-small"
        ref="emailslist"
        :dynamic="true"
        :items="researchResponse.emails"
        item-value="em_id"
        :item-height="30"
        :total="researchResponse.emails.length"
        :cursorOnRows="true"
        @itemclick="goToResearch"
      >
        <template v-slot:ths="{}">
          <th style="min-width:200px;">
            <span class="pointer">Key</span>
          </th>
          <th style="min-width:200px;">
            <span class="pointer">Objet</span>
          </th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.em_key }}
          </td>
          <td>
            {{ item.em_object }}
          </td>
        </template>
      </m-list-simple>
    </div>
  </section>
</template>

<script>
export default {
  name: "research",
  components: {},
  props: {
    researchVal: String
  },
  data() {
    return {
      user: {},
      researchResponse: {},
      tsSearch: null
    };
  },
  mounted() {},
  watch: {
    researchVal: function(val) {
      if (this.tsSearch) window.clearTimeout(this.tsSearch);
      this.tsSearch = window.setTimeout(() => {
        this.research(val);
      }, 1000);
    }
  },
  methods: {
    async research() {
      const body = {
        /*         filterDateStart: this.filterDateStart,
        filterDateEnd: this.filterDateEnd */
        text: this.researchVal
      };
      let response = await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/research",
        body
      );
      this.researchResponse = response.data.data;
    },
    goToResearch(item) {
      let route = "";
      if (item && item.us_id) route = "/admins/" + item.us_id + "/edit";
      if (item && item.em_id) route = "/mailing/" + item.em_id;
      if (item && item.xx_id) route = "/xxxs/" + item.xx_id;
      this.$router.push(route).catch(err => {});
      this.$emit("deleteResearch");
    }
  }
};
</script>

<style lang="scss" scoped>
/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-alert-enter-active,
.slide-alert-leave-active {
  transition: all 0.5s ease;
}
// une fois entrée et avant de partir
.slide-alert-enter-to,
.slide-alert-leave {
  transform: translateY(100px);
}
// avant de rentrer et une fois partie
.slide-alert-enter,
.slide-alert-leave-to {
  transform: translateY(-100px);
}
</style>
