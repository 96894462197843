import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
// import config from "../config.js";
import axios from "axios";

//import Home from "../views/Home.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Users from "../views/Users.vue";
import Contacts from "../views/Contacts.vue";
import Wines from "../views/Wines.vue";
import Invoices from "../views/Invoices.vue";
import Jures from "../views/Jures.vue";
import Candidats from "../views/Candidats.vue";
import Actualites from "../views/Actualites.vue";
import CandidatDetails from "../views/CandidatDetails.vue";
import Calendar from "../views/Calendar.vue";
import Preferences from "../views/Preferences.vue";
import PreferencesRights from "../views/PreferencesRights.vue";
import PreferencesLogs from "../views/PreferencesLogs.vue";
import PreferencesYears from "../views/PreferencesYears.vue";
import Mailinglists from "../views/Mailinglist.vue";
import Orders from "../views/Orders.vue";
import OrderDetails from "../views/OrderDetails.vue";
// import MPrintTable from "../components/MPrintTable.vue";
//import Import from "../views/Import.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: Home,
    meta: { menu: "home" }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/resetpass/:us_id/forget",
    name: "resetpass",
    component: Login,
    meta: {}
  },
  // {
  //   path: "/printtable/:table/pdf",
  //   name: "printtablepdf",
  //   component: MPrintTable,
  //   meta: { menu: "print", title: "projet-impression-tableau" }
  // },
  {
    path: "/mailinglists",
    name: "mailinglists",
    component: Mailinglists,
    meta: { menu: "mailinglists" }
  },
  {
    path: "/mailinglists/:id",
    name: "MailinglistsWinEdit",
    component: Mailinglists,
    meta: { menu: "mailinglists" }
  },
  // {
  //   path: "/profile/edit",
  //   name: "profile",
  //   component: Home,
  //   meta: {}
  // },
  {
    path: "/wines",
    name: "wines",
    component: Wines,
    meta: {}
  },
  {
    path: "/wines/:id/edit",
    name: "winesedit",
    component: Wines,
    meta: { checkyear: true }
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {}
  },
  {
    path: "/invoices/avoir",
    name: "InvoicesAvoirWinCreate",
    component: Invoices,
    meta: { checkyear: true }
  },
  {
    path: "/candidats/:id/avoir",
    name: "CandidatsAvoirWinCreate",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/candidats/:id/invoices/:in_id",
    name: "CandidatsInvoicesWinEdit",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/invoices/:id",
    name: "invoicesedit",
    component: Invoices,
    meta: { checkyear: true }
  },
  {
    path: "/jures",
    name: "jures",
    component: Jures,
    meta: {}
  },
  {
    path: "/jures/:id/edit",
    name: "JureWinEdit",
    component: Jures,
    meta: { checkyear: true }
  },
  {
    path: "/jures/:id/new",
    name: "JureWinEditNew",
    component: Jures,
    meta: { checkyear: true }
  },
  {
    path: "/candidats",
    name: "candidats",
    component: Candidats,
    meta: {}
  },
  {
    path: "/candidats/page/:page",
    name: "candidatspage",
    component: Candidats,
    meta: { type: "candidats", checkyear: true }
  },
  {
    path: "/candidats/:id/edit",
    name: "CandidatJureWinEdit",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/candidats/:id/new",
    name: "CandidatJureWinEditNew",
    component: Candidats,
    meta: { checkyear: true }
  },
  {
    path: "/candidats/:id",
    name: "candidatdetails",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/candidats/:id/wines/:wi_id",
    name: "CandidatDetailsWine",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/jures/:id",
    name: "juredetails",
    component: CandidatDetails,
    meta: { checkyear: true }
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {}
  },
  {
    path: "/calendar/:id/edit",
    name: "calendaredit",
    component: Calendar,
    meta: {}
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {}
  },
  {
    path: "/orders/:id/edit",
    name: "OrdersEdit",
    component: Orders,
    meta: {}
  },
  {
    path: "/orders/:id/step1",
    name: "OrderStep1",
    component: Orders,
    meta: {}
  },
  {
    path: "/orders/:id/step2",
    name: "OrderStep2",
    component: Orders,
    meta: {}
  },
  {
    path: "/orders/:id/details",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {}
  },
  {
    path: "/orders/candidats/:id/edit",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {}
  },
  /*
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
    meta: { type: "contacts" }
  }, */
  {
    path: "/contacts/import",
    name: "contactsimport",
    component: Contacts,
    meta: { type: "contacts" }
  },
  {
    path: "/contacts/group/:gr_id",
    name: "contactgroup",
    component: Contacts,
    meta: { type: "contacts" }
  },
  {
    path: "/contacts/group/:id/edit",
    name: "groupedit",
    component: Contacts,
    meta: { type: "contacts" }
  },
  {
    path: "/contacts/:id/edit",
    name: "contactedit",
    component: Contacts,
    meta: { type: "contacts" }
  },
  {
    path: "/admins",
    name: "admins",
    component: Users,
    meta: { type: "admins" }
  },
  {
    path: "/admins/page/:page",
    name: "admins",
    component: Users,
    meta: { type: "admins" }
  },
  {
    path: "/admins/:id/edit",
    name: "adminsedit",
    component: Users,
    meta: { type: "admins" }
  },
  {
    path: "/actualites",
    name: "Actualites",
    component: Actualites,
    meta: {}
  },
  {
    path: "/actualites/:id",
    name: "ActualitesWinEdit",
    component: Actualites,
    meta: {}
  },
  {
    path: "/preferences",
    name: "preferences",
    component: Preferences,
    meta: { menu: "preferences" }
  },
  {
    path: "/preferences/logs",
    name: "preferenceslogs",
    component: PreferencesLogs,
    meta: { menu: "preferences" }
  },
  {
    path: "/preferences/rights",
    name: "preferencesrights",
    component: PreferencesRights,
    meta: { menu: "preferences" }
  },
  {
    path: "/preferences/yearpreferences",
    name: "preferencesyearpreferences",
    component: PreferencesYears,
    meta: { menu: "preferences" }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  async function autolog() {
    // console.log("to, from", to, store.state.accesstoken);
    if (!store.state.accesstoken) {
      store.commit("set_connectedCharged");
      return false;
    }
    if (store.state.connected) return true;
    try {
      let response = await axios.post(
        process.env.VUE_APP_SERVER_URL + "/backoffice/1.0/autologin",
        {
          accesstoken: store.state.accesstoken
        }
      );
      if (response.data.err) {
        return false;
      }
      store.commit("set_connexion", {
        accesstoken: response.data.accesstoken,
        refreshtoken: response.data.refreshtoken,
        user: response.data.data,
        preferences: response.data.preferences
      });
      store.commit("set_connectedCharged");
      // store.dispatch("loadStats");
      return true;
    } catch (error) {
      console.error("ko", error);
      store.commit("set_connectedCharged");
      return false;
    }
  }
  if (to.name == "resetpass") {
    store.commit("set_connectedCharged");
    return next();
  }
  if (to.name == "login") {
    store.commit("set_connectedCharged");
    return next();
  }

  if (!store.state.connected) await autolog();
  if (to.name == "printtablepdf" && store.state.connected) return next();

  if (to.name == "home" && store.state.connected) return next();
  if (to.name == "home" && !store.state.connected)
    return next({ path: "/login" });
  if (!store.state.connected) return next({ path: "/login" });

  return next();
});

export default router;
