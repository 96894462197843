<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3 class="modal-title" v-if="co_id > 0">
              Modification fiche contact
            </h3>
            <h3 class="modal-title" v-if="co_id === -1">
              Création d'une fiche contact
            </h3>
            <p>Les champs avec une astérisque sont obligatoires</p>
          </div>
          <div class="d-flex flex-row relative">
            <div
              @click="changeAvatar"
              class="container-avatar pointer"
              :style="styleAvatar"
              v-tooltip
              data-bs-placement="left"
              title="cliquez pour modifier votre avatar"
            ></div>
            <input
              type="file"
              ref="inputImageAvatar"
              hidden
              name="inputImageAvatar"
              accept="image/png, image/jpeg"
              @change="fileJusteSelected($event)"
            />
            <div
              v-if="row_co.co_avatar"
              class="delete-avatar pointer d-flex justify-centent-center align-items-center"
              @click="deleteAvatar"
            >
              <icon color="#fff" name="times"></icon>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <m-form-select
                    label="Type de contact *"
                    :name="$Utils.randomstring('type')"
                    :items="itemsTypes"
                    v-model="row_co.co_type"
                    :disabled="disabledType"
                    :rules="[$Validation.mandatory]"
                  ></m-form-select>
                </div>
                <div class="col-md-6"></div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-select
                    label="Civilité"
                    v-model="row_co.co_civility"
                    :name="$Utils.randomstring('co_civility')"
                    :rules="[$Validation.mandatory]"
                    :items="$store.state.items_civility"
                  ></m-form-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Prénom *"
                    type="text"
                    v-model="row_co.co_firstname"
                    :name="$Utils.randomstring('co_firstname')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Nom *"
                    type="text"
                    v-model="row_co.co_name"
                    :name="$Utils.randomstring('co_name')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Adresse 1"
                    type="text"
                    v-model="row_co.co_address1"
                    :name="$Utils.randomstring('address')"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Adresse 2"
                    type="text"
                    v-model="row_co.co_address2"
                    :name="$Utils.randomstring('address')"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Code postal"
                    type="text"
                    v-model="row_co.co_zip"
                    :name="$Utils.randomstring('zip')"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Ville"
                    type="text"
                    v-model="row_co.co_city"
                    :name="$Utils.randomstring('city')"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="eMail *"
                    type="mail"
                    v-model="row_co.co_email"
                    :name="$Utils.randomstring('co_email')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Téléphone portable"
                    type="phone"
                    v-model="row_co.co_phone"
                    :name="$Utils.randomstring('co_phone')"
                    @keyup="oninputPhoneMobile($event)"
                  ></m-form-text>
                </div>
              </div>
              <m-form-password
                :name="$Utils.randomstring('pass')"
                :showRules="true"
                valid="shadow"
                display="row"
                :rules="rulesPassword"
                :required="false"
                @validPassword="validPassword"
              ></m-form-password>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button>
          <div class="ms-auto"></div>

          <div class="form-check">
            <label class="form-check-label" for="active"
              >Utilisateur actif</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="co_active"
              v-model="row_co.co_active"
            />
          </div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer ce contact ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "UserWinEdit",
  components: {},
  props: {
    value: { default: false, type: Boolean },
    co_id: Number
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      confirmdelete: false,
      row_co: { co_type: "" },
      password1: "",
      isValidPassword: false,
      errPassword: [],
      /// avatar
      avatarUrl: "",
      styleAvatar: "",
      fileSelected: { image: null, binary: null }
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.fileSelected = { image: null, binary: null };
        this.getContact();
      }
    },
    avatarUrl(val) {
      if (this.fileSelected.image) {
        this.styleAvatar =
          "background-image:url('" + this.fileSelected.binary + "')";
      } else {
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    }
  },
  computed: {
    disabledType() {
      return false;
    },

    /**
     * défini de manière dinamyque les types  à afficher dans  le select type d'utilisateur
     * en fonction  de la route, va chercher les types corespondants renseignés  dans le  store
     */
    itemsTypes() {
      let itemsTypes = this.$store.state.items_rights_roles
        .map(item => {
          return { value: item.riro_id, text: item.riro_label };
        })
        .filter(item => {
          let ok = false,
            userType = this.$store.state.user.co_type;
          if (userType == "superadmin" || userType == "admin") ok = true;
          if (userType == item.value) ok = true;
          return ok;
        });
      return itemsTypes;
    }
  },

  created() {},
  mounted() {
    this.contactType = this.$route.meta.type;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },
    oninputPhoneMobile(evt) {
      if (evt.key === "Backspace" || evt.key === "Delete") return;
      let phone = this.$Utils.formatedPhone(this.row_co.co_phone);
      this.row_co.co_phone = phone;
      // this.$forceUpdate();
    },

    _hasRight(row_rire) {
      if (this.row_co.co_rights[row_rire.rire_id] === -1) return false;
      if (this.row_co.co_rights[row_rire.rire_id] === 1) return true;
      return !!this.$_.find(this.$store.state.items_rights_rolesresources, {
        rire_id: row_rire.rire_id,
        riro_id: this.row_co.co_type
      });
    },
    rightCalculated(row_rire) {
      if (this._hasRight(row_rire)) return "OUI";
      return "NON";
    },
    classRightCalculated(row_rire) {
      if (this._hasRight(row_rire)) return "bg-success";
      return "bg-danger";
    },

    async getContact() {
      let params = {};
      if (this.co_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/contacts/" + this.co_id,
        { params }
      );
      let row_co = response.data.data;
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/contacts/${this.co_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;

      // formater le téléphone joli
      if (this.row_co.co_phone)
        row_co.co_phone = this.$Utils.addSpaceToMobile(row_co.co_phone);

      this.row_co = row_co;
    },

    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "co_name", text: "Nom" },
        { field: "co_firstname", text: "Prénom" },
        { field: "co_email", text: "Email" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_co[field.field]) err.push(field);
      }
      /*** vérification du mot de passe */
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          err.push({
            field: "co_password",
            text: this.errPassword[ierr]
          });
        }
      } else {
        this.row_co.co_password = this.password1;
      }
      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    async saveWin() {
      // reformater le téléphone mobile
      if (this.row_co.co_phone)
        this.row_co.co_phone = this.$Utils.removeSpaceToMobile(
          this.row_co.co_phone
        );

      let response;
      if (this.row_co.co_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/backoffice/1.0/contacts/" +
            this.row_co.co_id,
          this.row_co
        );
      } else {
        this.row_co.co_cgu_date = "0000-00-00 00:00:00";
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/contacts",
          this.row_co
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_co = response.data.data;
      if (this.fileSelected.image) {
        await this.saveImage(this.fileSelected.image, row_co.co_id);
      }

      this.$emit("emitContactActions", {
        action: "saved",
        co_id: row_co.co_id
      });
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("emitContactActions", { action: "canceled" });
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      let co_id = this.row_co.co_id;
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/contacts/" + co_id
      );
      this.confirmdelete = false;
      this.$emit("emitContactActions", { action: "deleted" });
    },
    /// avatar
    async deleteAvatar() {
      this.fileSelected = { image: null, binary: null };
      this.avatarUrl = "";
      this.row_co.co_avatar = "";
      let row_co = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/contacts/" +
          this.co_id +
          "/avatar"
      );
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/contacts/${this.co_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;
      this.$store.commit("set_reloadAvatar", row_co);
    },
    changeAvatar() {
      this.$refs.inputImageAvatar.click();
    },
    fileJusteSelected($event) {
      if (!$event.target.files.length) return;

      this.fileSelected.image = $event.target.files[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function(theFile) {
        return function(e) {
          me.fileSelected.binary = e.target.result;
          me.avatarUrl = e.target.result;
        };
      })($event.target.files[0]);
      reader.readAsDataURL($event.target.files[0]);
    },
    async saveImage(file, co_id) {
      if (!file) return;
      let formData = new FormData();
      formData.append("image", file, file.name);
      let row_co = await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/contacts/avatar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.fileSelected = { image: null, binary: null };
      this.$store.commit("set_reloadAvatar", row_co);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.delete-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #c8bfbf;
  box-shadow: 0 0 2px 0 grey;
  border-radius: 50%;
  padding: 7px;
}
</style>
