<template>
  <div v-if="row_in.wines.length">
    <div class="d-flex justify-content-between">
      <h4>
        Avoir N° {{ row_in.in_num }} | {{ row_in.wines.length }} vins | -{{
          row_in.in_price_ht
        }}
        € | le
        {{ row_in.in_date | formatDate }}
      </h4>
      <div class="d-flex">
        <div @click="print">
          <icon name="print"></icon>
        </div>
        <div @click="send" class="ms-2">
          <icon color="#3fb1dc" name="envelope"></icon>
        </div>
      </div>
    </div>
    <div>
      <table class="table table-striped ">
        <thead class="thead-light">
          <tr>
            <th>Ajouté le</th>
            <th>Crée par</th>
            <th>Nb de vins</th>
            <th>Envoyé le</th>
            <th v-if="$store.state.user.us_type === 'superadmin'"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ row_in.createdAt | formatDate }}</td>
            <td>{{ row_in.user | formatContactNameSimple }}</td>
            <td>{{ row_in.wines.length }}</td>
            <td>{{ row_in.createdAt | formatDate }}</td>
            <td v-if="$store.state.user.us_type === 'superadmin'">
              <div class="d-flex justify-content-end">
                <div @click.stop="edit()" class="me-2">
                  <icon name="edit"></icon>
                </div>
                <div @click.stop="deleteConfirm()" class="me-3">
                  <icon color="#950605" name="trash-alt"></icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  <m-list-simple
        tableClass="table-hover table-striped"
        ref="winelist"
        :dynamic="true"
        :items="row_in.wines"
        item-value="wi_id"
        :item-height="30"
        :skip="0"
        :total="row_in.wines.length"
        :cursorOnRows="true"
        @itemclick="editWine"
      >
        <template v-slot:ths="{}">
          <th>
            <span>Ajouté le</span>
          </th>
          <th>
            <span>Nom</span>
          </th>
          <th><span>Dénomination</span></th>
          <th>
            <span>Couleur</span>
          </th>

          <th><span>Millésime</span></th>

          <th></th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.createdAt | formatDate }}
          </td>
          <td>
            {{ item | formatWineName}}
          </td>
          <td>
            {{
              item.denomination
                ? item.denomination.de_name
                : item.wi_denomination
            }}
          </td>
          <td>
            {{ item.wi_couleur | formatWineColor }}
          </td>

          <td>
            {{ item.wi_millesime }}
          </td>

          <td>
            <div class="d-flex justify-content-end">
              <div @click.stop="editWine(item)" class="me-3">
                <icon name="edit"></icon>
              </div>
            </div>
          </td>
        </template>
      </m-list-simple> -->
    </div>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous cet avoir ? Cette action va revalider le ou les vins de l'avoir"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteA()"
    ></m-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "FrameAvoir",
  components: {},
  props: {
    row_in: Object,
    keyload: Number
  },
  data() {
    return {
      confirmdelete: false
    };
  },
  watch: {
    keyload: function(v) {}
  },
  async mounted() {},
  methods: {
    emitCreateAvoir() {
      this.$emit("emitCreateAvoir", this.row_in.in_id);
    },
    edit() {
      this.$emit("emitAvoirEdit", this.row_in);
    },
    deleteConfirm() {
      this.confirmdelete = true;
    },
    async deleteA() {
      this.confirmdelete = false;
      let response = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/invoices/" +
          this.row_in.in_id
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat",
          text: "L'avoir " + response.data.data.in_num + " a bien été supprimé",
          color: "green"
        });
      }
      this.$emit("emitAvoirAction");
    },
    print() {},
    send() {}
    /*  editWine(item) {
      // console.log("item", item);
      this.$emit("emitEditWine", item);
    }, */
  }
};
</script>

<style lang="scss" scoped></style>
