<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Ajouter / Modifier un groupe</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <m-form-text
                label="Nom du groupe"
                v-model="row_gr.gr_name"
                name="name"
                autocomplete
                :disabled="row_gr.gr_key === 'caddy'"
              ></m-form-text>
            </div>
          </div>
          <div class="row d-flex align-items-end mt-2">
            <div class="col-md-6">
              <m-form-combobox
                v-model="newContact"
                label="Choisir les contacts du groupe"
                :store-url="$config.server_url + '/backoffice/1.0/contacts'"
                item-value="co_id"
                :item-text="$options.filters.formatContactNameSimple"
                :item-text-params="'co_'"
              ></m-form-combobox>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary " @click="addContactInGroup">
                Ajouter
              </button>
              <button class="btn btn-primary ms-3" @click="advancedSearch">
                Recherche avancée
              </button>
            </div>
          </div>
          <div class="my-3 bg-light p-2" v-if="showAdvancedSearch">
            <contact-advanced-search
              v-model="showAdvancedSearch"
              :gr_id="gr_id"
              @searchLoaded="emitAdvancedSearch"
              @canceled="emitAdvancedSearchcanceled"
            ></contact-advanced-search>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <m-list-simple
                tableClass="table-hover table-striped table-small"
                ref="contactlist"
                :dynamic="true"
                :items="contactsToShow"
                item-value="co_id"
                :item-height="30"
                :limit="contacts_limit"
                :skip="contacts_skip"
                :total="contacts_total"
                :cursorOnRows="true"
              >
                <template v-slot:ths="{}">
                  <th>
                    <span>Nom</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Statut</span>
                  </th>
                  <th></th>
                </template>
                <template v-slot:tds="{ item }">
                  <td>
                    {{ $options.filters.formatContactNameSimple(item, "co_") }}
                  </td>
                  <td>
                    {{ item.co_email }}
                  </td>
                  <td>
                    {{ item.co_status }}
                  </td>
                  <td style="width: 30px;">
                    <div
                      @click="deleteContactInGroup(item)"
                      v-tooltip
                      data-bs-placement="bottom"
                      title="suprimer le contact du groupe"
                    >
                      <icon color="red" name="times"></icon>
                    </div>
                  </td>
                </template>
              </m-list-simple>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="tryToDelete"
            :disabled="contacts_total > 0"
          >
            Supprimer
          </button>
          <div class="ms-auto"></div>
          <button class="btn btn-secondary" @click="cancelWin">
            Annuler
          </button>
          <button class="btn btn-primary" @click="saveWin">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmDeleteDialog"
      text="Souhaitez-vous vraiment supprimer ce groupe ?"
      title="Confirmation"
      @canceled="confirmDeleteDialog = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
      color="red"
    ></m-message-dialog>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
export default {
  name: "groupwinedit",
  components: {},
  props: {
    value: Boolean,
    gr_id: [Number, String]
  },
  data() {
    return {
      ///
      row_gr: {},
      contacts_total: 0,
      contacts_skip: 0,
      contacts_limit: 0,
      contacts: [],
      contactsToShow: [],
      newContact: {},
      confirmDeleteDialog: false,
      showAdvancedSearch: false,
      // messages
      dialogErrTxt: "",
      dialogErr: false
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },

  watch: {
    value: async function(val) {
      if (val) {
        this.contactsToShow = [];
        this.newContact = {};
        this.confirmDeleteDialog = false;
        await this.loadGroup();
        if (this.gr_id > 0) await this.loadContacts();
      }
    }
  },

  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadGroup() {
      let params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/groups/" + this.gr_id,
        { params }
      );
      if (!response || !response.data) return;
      this.row_gr = response.data.data;
    },
    async loadContacts() {
      let params = { gr_id: this.gr_id };
      if (this.filterTextContact) params.text = this.filterTextContact;
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/contacts",
        { params }
      );
      if (!response || !response.data) return;
      this.contacts = response.data.data;
      this.contactsToShow = response.data.data;
      this.contacts_total = response.data.meta.total;
    },
    advancedSearch() {
      this.showAdvancedSearch = true;
    },
    emitAdvancedSearch() {
      this.loadContacts();
      this.showAdvancedSearch = false;
    },
    emitAdvancedSearchcanceled() {
      this.showAdvancedSearch = false;
    },
    addContactInGroup() {
      if (!this.newContact.co_id) return;
      //  vérifier s'il y es déjà
      let index = this.contactsToShow.findIndex(el => {
        return el.co_id === this.newContact.co_id;
      });
      if (index === -1) this.contactsToShow.unshift(this.newContact);
      else {
        this.$store.dispatch("showToast", {
          title: this.$options.filters.formatContactNameSimple(
            this.newContact,
            "co_"
          ),
          text: "Contact est déjà dans le groupe",
          color: "#2aaa2a"
        });
      }
      this.newContact = {};
    },
    deleteContactInGroup(item) {
      var tooltipElement = document.querySelector(".bs-tooltip-bottom");
      var tooltip = Tooltip.getInstance(tooltipElement);
      tooltip.hide();
      let index = this.contactsToShow.findIndex(el => {
        return el.co_id === item.co_id;
      });
      this.contactsToShow.splice(index, 1);
    },
    async saveWin() {
      if (!this.row_gr.gr_name) {
        this.dialogErrTxt =
          "<span class='bis'>Le champ nom du groupe est obligatoire </span><br>";
        this.dialogErr = true;
        return;
      }
      let response;
      let data = {
        gr_name: this.row_gr.gr_name,
        contacts: this.contactsToShow
      };
      if (this.gr_id > 0) {
        response = await this.$axios.put(
          this.$config.server_url + "/backoffice/1.0/groups/" + this.gr_id,
          data
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/groups",
          data
        );
      }
      if (!response || !response.data) return;
      this.$emit("input", false);
      this.$emit("emitGroupsActions", {
        action: "saved",
        gr_id: response.data.data.gr_id
      });
    },
    cancelWin() {
      // console.log("coucou");
      this.$emit("input", false);
      this.$emit("emitGroupsActions", { action: "canceled" });
    },
    tryToDelete() {
      if (this.contacts_total > 0) {
        this.$store.dispatch("showToast", {
          title: "Erreur",
          text:
            "Ce groupe contient des contacts, vous ne pouvez pas le supprimer.",
          color: "red"
        });
        return;
      }
      if (this.row_gr.gr_key === "caddy") {
        return;
      }
      this.confirmDeleteDialog = true;
    },
    async deleteWin() {
      let params = {};
      let response = await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/groups/" + this.gr_id,
        { params }
      );
      if (!response || !response.data) return;
      this.$emit("input", false);
      this.$emit("emitGroupsActions", { action: "deleted" });
    }
  }
};
</script>
<style lang="scss"></style>
