<template>
  <section>
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div
          class="icon-title d-flex align-items-center justify-content-center"
        >
          <icon width="30" height="30" name="bolt"></icon>
        </div>
        <div>
          <div>Liste des {{ user_total }} administrateurs</div>
          <div class="fs-6 fw-light">
            Les administrateurs qui ont acces à ce backoffice
          </div>
        </div>
      </h3>
    </div>
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div>
        <m-form-text
          label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadUsers()"
        ></m-form-text>
      </div>
      <div>
        <button class="btn btn-primary" type="button" @click="addUsers">
          Nouveau
        </button>
      </div>
    </div>
    <div class="frame">
      <users-list
        ref="usersList"
        :filterText="filterText"
        :showNoActive="showNoActive"
        :loadUsersGo="loadUsersGo"
        @userLoaded="userLoaded"
      />
    </div>

    <user-win-edit
      v-model="userWinNew"
      :us_id="-1"
      @close="userWinNew = false"
      @saved="newuserWinEditAction"
      @deleted="newuserWinEditAction"
      @canceled="newuserWinEditAction"
    >
    </user-win-edit>
  </section>
</template>

<script>
export default {
  name: "users",
  components: {},
  data() {
    return {
      user: null,
      filterText: "",
      user_total: 0,
      loadUsersGo: 0,
      usersType: this.$route.meta.type,
      userWinNew: false,
      itemsTypes: [],
      showNoActive: false
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canUsersAccess")) {
      this.$router.push("/");
    }
    this.user = this.$store.state.user;
    await this.loadUsers(0);
  },
  watch: {
    $route(to, from) {
      this.usersType = this.$route.meta.type;
    }
  },
  computed: {
    titre() {
      if (!this.$store.state.usersTypes[this.usersType]) return;
      let titre = this.user_total + " ";
      titre += this.$store.state.usersTypes[this.usersType].title;
      if (this.user_total > 1) titre += "s";
      return titre;
    }
  },
  methods: {
    loadUsers() {
      this.loadUsersGo++;
    },
    userLoaded(total) {
      this.user_total = total;
    },
    addUsers() {
      this.$router.push("/" + this.usersType + "/-1/edit").catch(err => {});
      this.userWinNew = true;
    },
    newuserWinEditAction(co_id) {
      this.$router.push("/" + this.usersType).catch(err => {});
      this.loadUsers();
    }
  }
};
</script>

<style lang="scss" scoped></style>
