<template>
  <div class="row">
    <section class="col-md-3 pe-0">
      <div class="frame">
        <div class="d-flex justify-content-between mb-3">
          <h4>{{ groups_total }} groupes</h4>
          <button
            class="btn btn-primary btn-sm btn-xs"
            type="button"
            @click="addGroup"
          >
            <icon name="plus"></icon>
          </button>
        </div>

        <m-list-simple
          tableClass="table-hover table-striped table-small"
          ref="grouplist"
          :dynamic="true"
          :items="groups"
          item-value="gr_id"
          :item-height="30"
          :skip="groups_skip"
          :total="groups_total"
          :cursorOnRows="true"
          :item-selected="groupSelected.gr_id"
          @itemclick="selectGroup"
        >
          <template v-slot:ths="{}"> </template>
          <template v-slot:tds="{ item }">
            <td>
              {{ item.gr_name }}
            </td>
            <td style="width:25px">
              <div @click.stop="editGroup(item)" v-if="!item.no_edit">
                <icon name="edit"></icon>
              </div>
              <div
                @click.stop="deleteSearch(item)"
                v-if="item.gr_key === 'search'"
              >
                <icon name="times"></icon>
              </div>
            </td>
          </template>
        </m-list-simple>
      </div>
    </section>
    <section class="col-md-3 pe-0">
      <div class="frame">
        <div class="d-flex justify-content-between mb-3">
          <h4>{{ contacts_total }} contacts</h4>
          <div>
            <button
              v-if="groupSelected.gr_id === 'all'"
              class="btn btn-primary btn-sm btn-xs"
              type="button"
              @click="importContacts"
              v-tooltip
              data-bs-placement="top"
              title="Importer des contacts"
            >
              <icon name="download"></icon>
            </button>
            <button
              class="btn btn-primary btn-sm btn-xs ms-1"
              type="button"
              @click="exportContacts"
              v-tooltip
              data-bs-placement="top"
              title="Exporter des contacts"
            >
              <icon name="upload"></icon>
            </button>
            <button
              class="btn btn-primary btn-sm btn-xs ms-1"
              type="button"
              @click="addContact"
              v-tooltip
              data-bs-placement="top"
              title="Ajouter un contact"
            >
              <icon name="plus"></icon>
            </button>
          </div>
        </div>
        <div class="d-flex align-items-end mb-2">
          <m-form-text
            class="flex-grow-1"
            inputGroupClass="input-group-xs"
            label="Rech. textuelle"
            v-model="filterTextContact"
            name="search"
            autocomplete
            @input="loadContacts()"
          ></m-form-text>
          <button
            class="btn btn-primary ms-2 btn-sm"
            @click="openAdvancedSearch"
          >
            Rech. avancée
          </button>
        </div>
        <div>
          <div class="d-flex">
            <div class="flex-grow-1">
              <m-list-simple
                tableClass="table-hover table-striped table-small"
                ref="contactlist"
                :dynamic="true"
                :items="contacts"
                item-value="co_id"
                :item-height="30"
                :limit="contacts_limit"
                :skip="contacts_skip"
                :total="contacts_total"
                :cursorOnRows="true"
                :item-selected="contactSelected.co_id"
                @itemclick="showContactDetails"
              >
                <template v-slot:ths="{}"> </template>
                <template v-slot:tds="{ item }">
                  <td>
                    {{ $options.filters.formatContactNameSimple(item, "co_") }}
                  </td>
                </template>
              </m-list-simple>
            </div>
            <div v-if="contacts_total > 0">
              <div
                v-for="letter in letters"
                :key="letter"
                style="height: 20px;"
              >
                <button
                  :class="filterLetter === letter ? 'active' : ''"
                  @click.stop="clickLetter(letter)"
                  class="btn btn-primary btn-lettres btn-xs w-100 h-100 d-flex justify-content-center align-item-center"
                >
                  {{ letter }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="col-md-6">
      <div class="frame">
        <div class="d-flex justify-content-between mb-3">
          <h4>
            {{
              $options.filters.formatContactNameSimple(contactSelected, "co_")
            }}
          </h4>
          <button class="btn btn-primary" type="button" @click="editContact">
            Modifier
          </button>
        </div>
        <div>
          <contact-details
            :keyload="keyloadDetail"
            :co_id="contactSelected.co_id"
          ></contact-details>
        </div>
      </div>
    </section>
    <group-win-edit
      v-model="groupEdit"
      :gr_id="groupToEdit.gr_id"
      @emitGroupsActions="emitGroupsActions"
    ></group-win-edit>
    <contact-win-edit
      v-model="contactEdit"
      :co_id="contactToEdit.co_id"
      @emitContactActions="emitContactActions"
    ></contact-win-edit>
    <contact-advanced-search-win
      v-model="advancedSearch"
      @saved="emitSearchSaved"
    ></contact-advanced-search-win>
    <import-win
      v-model="importWin"
      @emitImportActions="emitImportActions"
    ></import-win>
  </div>
</template>

<script>
import ContactDetails from "./ContactDetails.vue";
import GroupWinEdit from "./GroupWinEdit.vue";
import { Tooltip } from "bootstrap";
export default {
  name: "contacts",
  components: { GroupWinEdit, ContactDetails },
  data() {
    let tabLetters = [];
    for (let i = 65; i < 91; i++) {
      tabLetters.push(String.fromCharCode(i));
    }
    return {
      // les groups
      groups: [],
      groups_total: 0,
      groups_skip: 0,
      groupSelected: {},
      groupToEdit: {},
      groupEdit: false,
      /// les contacts
      contacts_total: 0,
      contacts_skip: 0,
      contacts_limit: 0,
      contacts: [],
      filterTextContact: "",
      contactSelected: {},
      contactEdit: false,
      contactToEdit: {},
      letters: tabLetters,
      filterLetter: "",
      importWin: false,
      // recherche avancée
      advancedSearch: false,
      //  les details
      keyloadDetail: 0
    };
  },
  async mounted() {
    window.addEventListener("click", this.onClickWindow);
    if (this.$route.name == "groupedit") {
      this.groupToEdit = { gr_id: parseInt(this.$route.params.id) };
      this.editGroup({ gr_id: parseInt(this.$route.params.id) });
    } else if (this.$route.name == "groupedit") {
      if (parseInt(this.$route.params.id) === -1) this.addContact();
      else {
        this.contactSelected = { co_id: parseInt(this.$route.params.id) };
        this.editContact();
      }
    } else {
      await this.loadGroups();
    }
  },
  destroyed() {
    window.removeEventListener("click", this.onClickWindow);
  },
  watch: {},
  computed: {},
  methods: {
    onClickWindow() {
      if (this.filterLetter) this.filterLetter = "";
    },
    // ajouter un groupe
    addGroup() {
      this.groupToEdit = { gr_id: -1 };
      this.groupEdit = true;
      this.$router.push("/contacts/group/-1").catch(err => {});
    },
    // édition d'un groupe
    editGroup(item) {
      this.groupToEdit = item;
      this.groupEdit = true;
      this.$router
        .push("/contacts/group/" + item.gr_id + "/edit")
        .catch(err => {});
    },
    // suprimer le groupe "ma recherche"
    async deleteSearch() {
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/groups/search"
      );
      this.groupSelected = {};
      this.loadGroups();
    },
    // chargement des groupes et ajout du groupe ttes les fiches
    async loadGroups(search = false) {
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/groups"
      );
      if (!response || !response.data) return;
      this.groups = response.data.data;
      // tris du tableau
      this.groups.sort((a, b) => {
        if (a.gr_key === "caddy" || a.gr_key === "search") return -1; // si a n’a pas de note, je veux qu’il se place avant
        if ((a.gr_key === "caddy") | (b.gr_key === "search")) return 1; // idem
      });
      // ajout de no edit au groupe recherche
      let groupSearch = this.groups.find(el => {
        return el.gr_key === "search";
      });
      if (groupSearch) groupSearch.no_edit = true;
      // ajout du group "tous"
      this.groups.unshift({
        gr_id: "all",
        gr_name: "Tous les contacts",
        no_edit: true
      });
      this.groups_total = response.data.meta.total + 1;
      if (!this.groupSelected.gr_id) this.groupSelected = this.groups[0];
      if (search) {
        this.groupSelected = this.groups[1];
      }
      this.loadContacts();
    },
    // selectionner un group et appel le chargement des contacts du group
    selectGroup(item) {
      this.groupSelected = item;
      let id = item.gr_id;
      if (item.gr_id == "all") id = 0;
      this.$router.push("/contacts/group/" + id).catch(err => {});
      this.loadContacts();
    },
    // emit une fois le groupe enregistré
    emitGroupsActions(what) {
      // console.log("what", what);
      if (what.action === "saved") {
        this.$router
          .push("/contacts/group/" + this.groupSelected.gr_id)
          .catch(err => {});
        this.loadGroups();
      }
      if (what.action === "canceled") {
        this.$router
          .push("/contacts/group/" + this.groupSelected.gr_id)
          .catch(err => {});
      }
      if (what.action === "deleted") {
        this.$router
          .push("/contacts/group/" + this.groupSelected.gr_id)
          .catch(err => {});
        this.loadGroups();
      }
    },
    openAdvancedSearch() {
      this.advancedSearch = true;
    },
    emitSearchSaved() {
      this.loadGroups(true);
    },
    clickLetter(letter) {
      this.filterLetter = letter;
      this.loadContacts();
    },
    // chargement des contacts en fonction du group sélectionné
    async loadContacts() {
      let params = { gr_id: this.groupSelected.gr_id };
      if (this.filterTextContact) params.text = this.filterTextContact;
      if (this.filterLetter) params.letter = this.filterLetter;
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/contacts",
        { params }
      );
      if (!response || !response.data) return;
      this.contacts = response.data.data;
      this.contacts_total = response.data.meta.total;
      if (this.contacts.length) this.contactSelected = this.contacts[0];
      if (this.filterLetter) this.filterLetter = "";
    },
    // affichage des détails du contact
    showContactDetails(item) {
      this.contactSelected = item;
    },
    // ajouter un nouveau contact
    addContact() {
      var tooltipElement = document.querySelector(".bs-tooltip-top");
      var tooltip = Tooltip.getInstance(tooltipElement);
      tooltip.hide();
      this.contactToEdit = { co_id: -1 };
      this.$router.push("/contacts/-1/edit").catch(err => {});
      this.contactEdit = true;
    },
    // éditer un contact
    editContact() {
      this.contactToEdit = this.contactSelected;
      this.$router
        .push("/contacts/" + this.contactToEdit.co_id + "/edit")
        .catch(err => {});
      this.contactEdit = true;
    },
    // emit une fois le contact enregistré, supprimé ou annulé
    async emitContactActions(what) {
      if (what.action === "canceled") {
        this.$router
          .push("/contacts/group/" + this.groupSelected.gr_id)
          .catch(err => {});
        return;
      }
      if (what.action === "saved") {
        await this.loadContacts();
        let co_index = this.contacts.findIndex(el => {
          return el.co_id === what.co_id;
        });

        if (co_index > -1) this.contactSelected = this.contacts[co_index];
      }
      if (what.action === "deleted") {
        this.loadContacts();
      }
      this.keyloadDetail++;
      this.contactEdit = false;
      this.$router
        .push("/contacts/group/" + this.groupSelected.gr_id)
        .catch(err => {});
    },
    importContacts() {
      var tooltipElement = document.querySelector(".bs-tooltip-top");
      var tooltip = Tooltip.getInstance(tooltipElement);
      tooltip.hide();
      this.$router.push("/contacts/import").catch(err => {});
      this.importWin = true;
    },
    emitImportActions() {
      this.$router.push("/contacts/group/0").catch(err => {});
    },
    async exportContacts() {
      let query = `?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;
      if (this.filterTextContact) query += `&text=${this.filterTextContact}`;
      if (this.filterLetter) query += `&letter=${this.filterLetter}`;
      if (this.groupSelected.gr_id !== "all")
        query += `&gr_id=${this.groupSelected.gr_id}`;
      window.open(
        `${this.$config.server_url}/backoffice/1.0/contacts/export${query}`,
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-xs {
  height: 25px;
  padding: 0.2rem 0.3rem;
}
.frame {
  height: calc(100vh - 90px);
  overflow: overlay;
  margin-bottom: 0;
}
.btn-lettres {
  font-size: 8px;
}
</style>
