<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Recherche avancée dans les contacts</h3>
        </div>
        <div class="modal-body">
          <contact-advanced-search
            v-model="showAdvancedSearch"
            gr_id="search"
            @searchLoaded="emitAdvancedSearch"
            @canceled="emitAdvancedSearchcanceled"
          ></contact-advanced-search>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mconfirmdialog",
  props: {
    value: Boolean
  },
  data() {
    return {
      showAdvancedSearch: true
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  watch: {
    value: function(val) {}
  },
  components: {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.emitAdvancedSearchcanceled();
      }
    },
    emitAdvancedSearchcanceled() {
      this.$emit("input", false);
    },
    emitAdvancedSearch() {
      this.saveWin();
    },
    saveWin() {
      this.$emit("input", false);
      this.$emit("saved", false);
    }
  }
};
</script>
<style lang="scss" scoped></style>
