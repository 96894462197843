<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Importer des contacts</h3>
        </div>
        <div class="modal-body">
          <p>Importez depuis un fichier excel</p>
          <m-form-file
            class="input-avatar mb-2"
            label="sélectionnez un fichier"
            ref="inputAvatar"
            @inputfile="savefile"
          />
          <div
            v-if="importDone"
            class="alert alert-success"
            v-html="alertOkTxt"
          ></div>
          <div
            v-if="importDone && alertErrTxt"
            class="alert alert-danger"
            v-html="alertErrTxt"
          ></div>
          <div class="mt-3">
            <div>
              Le fichier excel importé doit respecter un certain format.
            </div>
            <button class="btn btn-primary btn-sm" @click="downloadHelpImport">
              Télécharger le model
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            v-if="!importDone"
            @click="cancelWin"
          >
            Annuler
          </button>
          <button class="btn btn-primary" @click="importfile">
            {{ importDone ? "Ok" : "Importer" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "importwin",
  props: {
    value: Boolean
  },
  data() {
    return {
      dialog: false,
      file: {},
      alertOkTxt: "",
      alertErrTxt: "",
      importDone: false
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  watch: {
    value: function(val) {
      this.dialog = val;
      this.alertOkTxt = "";
      this.alertErrTxt = "";
      this.importDone = false;
    }
  },
  components: {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async savefile(files) {
      if (files.length) this.file = files[0];
    },
    async importfile() {
      if (this.importDone) {
        this.cancelWin();
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      let response = await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/contacts/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.importDone = true;

      // afficher le rapport
      // afficher le nombre de ligne mise à jour ou crées
      this.alertOkTxt = `${response.data.rapport.ok.updated} contacts(s) mi(s) à jour. <br/>
      ${response.data.rapport.ok.created} contacts(s) créé(s). <br/>`;

      // afficher les erreurs s'il y en a
      if (response.data.rapport.errors.length) {
        this.alertErrTxt = `${response.data.rapport.errors.length} erreur(s) trouvée (s) : </br>`;
        for (let ierr = 0; ierr < response.data.rapport.errors.length; ierr++) {
          const err = response.data.rapport.errors[ierr];
          this.alertErrTxt += `ligne ${err.line} : <br/> <ul>`;
          for (let ierr2 = 0; ierr2 < err.taberr.length; ierr2++) {
            const err2 = err.taberr[ierr2];
            this.alertErrTxt += `<li> ${err2} </li>`;
          }
          this.alertErrTxt += `</ul>`;
        }
      }
    },
    downloadHelpImport() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/files/help?help=importcontacts&token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    cancelWin() {
      this.alertOkTxt = "";
      this.alertErrTxt = "";
      this.$emit("input", false);
      this.$emit("emitImportActions");
    }
  }
};
</script>
<style lang="scss" scoped></style>
