<template>
  <div>
    <div class="frame px-5 bg-gray">
      <div class="row mb-0">
        <div class="col-md-12 d-flex justify-content-between">
          <div>
            <h4 v-if="row_pa.pa_candidat && row_pa.pa_jure">
              Candidat ET juré
            </h4>
            <h4 v-else-if="row_pa.pa_candidat">
              Candidat
            </h4>
            <h4 v-else-if="row_pa.pa_jure">
              Juré
            </h4>
          </div>
          <div>
            <button @click="emitCandidat" class="btn btn-primary btn-sm">
              Modifier
            </button>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <h5>Entreprise</h5>
          <div>{{ row_pa.pa_society }}</div>
          <div v-html="$options.filters.formatAddress(row_pa, 'pa_')"></div>
          <div v-if="row_pa.pa_web && row_pa.pa_web != 'http://'">
            <a :href="row_pa.pa_web" target="blank">{{ row_pa.pa_web }}</a>
          </div>
        </div>
        <div class="col-md-6">
          <h5>Contact</h5>
          <div>
            {{ $options.filters.formatContactNameSimple(row_pa, "pa_", true) }}
          </div>
          <div v-if="row_pa.pa_fonction">
            Fonction : {{ row_pa.pa_fonction }}
          </div>
          <div v-if="row_pa.pa_phone_fix">
            Tél fixe : {{ row_pa.pa_phone_fix }}
          </div>
          <div v-if="row_pa.pa_phone_mobile">
            Portable : {{ row_pa.pa_phone_mobile }}
          </div>
          <div>
            Email :
            <span
              v-html="$options.filters.formatEmail(row_pa.login.lo_login)"
            ></span>
          </div>
          <div v-if="row_pa.login.lo_analytique_code">
            Code analytique : {{ row_pa.login.lo_analytique_code }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="row_pa.pa_candidat">
          <p class="fw-bold">Je souhaite être candidat</p>
          <h5>Adresse de facturation :</h5>
          <div>{{ row_pa.pa_society_fac }}</div>
          <div>{{ row_pa.pa_address1_fac }}</div>
          <div>{{ row_pa.pa_address2_fac }}</div>
          <div>{{ row_pa.pa_zip_fac }} {{ row_pa.pa_city_fac }}</div>
        </div>
        <div class="col-md-6" v-if="row_pa.pa_jure">
          <p class="fw-bold">Je souhaite être juré</p>
          <h5>Réponses :</h5>
          <div>
            Première participation :
            {{ row_pa.pa_firstparticipation | formatYesNoInt }}
          </div>
          <div>
            Catégorie :
            {{
              row_pa.pa_typejure === 9
                ? row_pa.pa_typejure_other
                : $options.filters.formatvalue(
                    row_pa.pa_typejure,
                    "items_typejure",
                    "uppercase",
                    "singular"
                  )
            }}
          </div>
          <div>
            Cours de dégustation :
            {{ row_pa.pa_coursdegustation | formatYesNoInt }}
          </div>
          <div class="mb-3">
            Liens :
            <span v-if="row_pa.pa_liensexistant_more">{{
              row_pa.pa_liensexistant_more
            }}</span>
            <span v-else>{{ row_pa.pa_liensexistant | formatYesNoInt }}</span>
          </div>
          <!-- <div>Centre : ?</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ReadCandidatJure",
  components: {},
  props: {
    row_pa: Object,
    keyload: Number
  },
  data() {
    return {};
  },
  watch: {
    keyload: function(v) {}
  },
  async mounted() {},
  methods: {
    emitCandidat() {
      this.$emit("editCandidatJure");
    }
  }
};
</script>

<style lang="scss" scoped></style>
