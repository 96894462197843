<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3
              class="modal-title"
              v-if="us_id > 0 && us_id != $store.state.user.us_id"
            >
              Modification fiche utilisateur
            </h3>
            <h3 class="modal-title" v-if="us_id === -1">
              Création d'une fiche utilisateur
            </h3>
            <h3 class="modal-title" v-if="us_id == $store.state.user.us_id">
              Modifier ma fiche
            </h3>
            <p>Seuls les champs avec une astérisque sont obligatoires</p>
          </div>
          <div class="d-flex flex-row relative">
            <div
              @click="changeAvatar"
              class="container-avatar pointer"
              :style="styleAvatar"
              v-tooltip
              data-bs-placement="left"
              title="cliquez pour modifier votre avatar"
            ></div>
            <input
              type="file"
              ref="inputImageAvatar"
              hidden
              name="inputImageAvatar"
              accept="image/png, image/jpeg"
              @change="fileJusteSelected($event)"
            />
            <div
              v-if="row_us.us_avatar"
              class="delete-avatar pointer d-flex justify-centent-center align-items-center"
              @click="deleteAvatar"
            >
              <icon color="#fff" name="times"></icon>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row mb-2">
                <div class="col-md-6">
                  <m-form-select
                    label="Type de contact *"
                    class="mb-2"
                    :name="$Utils.randomstring('type')"
                    :items="itemsTypes"
                    v-model="row_us.us_type"
                    :disabled="disabledType"
                    :rules="[$Validation.mandatory]"
                  ></m-form-select>
                  <m-form-text
                    label="Société"
                    type="text"
                    v-model="row_us.us_society"
                    :name="$Utils.randomstring('us_society')"
                  ></m-form-text>
                  <m-form-select
                    class="mb-2"
                    label="Civilité"
                    v-model="row_us.us_civility"
                    :name="$Utils.randomstring('us_civility')"
                    :rules="[$Validation.mandatory]"
                    :items="$store.state.items_civility"
                  ></m-form-select>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <m-form-text
                        label="Prénom *"
                        type="text"
                        v-model="row_us.us_firstname"
                        :name="$Utils.randomstring('us_firstname')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                    <div class="col-md-6">
                      <m-form-text
                        label="Nom *"
                        type="text"
                        v-model="row_us.us_name"
                        :name="$Utils.randomstring('us_name')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="bg-light p-2">
                    <m-form-password
                      :name="$Utils.randomstring('pass')"
                      :showRules="true"
                      valid="shadow"
                      display="row"
                      :rules="rulesPassword"
                      :required="false"
                      @validPassword="validPassword"
                    ></m-form-password>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="bg-light p-2">
                    <m-form-text
                      label="Adresse 1"
                      type="text"
                      v-model="row_us.us_address1"
                      :name="$Utils.randomstring('us_address1')"
                    ></m-form-text>
                    <m-form-text
                      class="mt-1 mb-2"
                      type="text"
                      v-model="row_us.us_address2"
                      :name="$Utils.randomstring('us_address2')"
                    ></m-form-text>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <m-form-text
                          label="Code postal"
                          type="text"
                          v-model="row_us.us_zip"
                          :name="$Utils.randomstring('us_zip')"
                        ></m-form-text>
                      </div>
                      <div class="col-md-6">
                        <m-form-text
                          label="Ville"
                          type="text"
                          v-model="row_us.us_city"
                          :name="$Utils.randomstring('us_city')"
                        ></m-form-text>
                      </div>
                    </div>
                  </div>
                  <m-form-text
                    class="mb-2"
                    label="Téléphone portable"
                    type="phone"
                    v-model="row_us.us_phone"
                    :name="$Utils.randomstring('us_phone')"
                    @keyup="oninputPhoneMobile($event)"
                  ></m-form-text>
                  <m-form-text
                    label="eMail *"
                    type="mail"
                    v-model="row_us.us_email"
                    :name="$Utils.randomstring('us_email')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
            </div>
          </div>

          <div class="row bg-gray pt-3">
            <h4>Les droits</h4>
            <div class="col-md-12">
              <div class="hscroll">
                <table class="table table-bordered table-small bg-white">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-center">Les droits</th>
                      <th class="text-center" colspan="4">L'utilisateur</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="min-width:170px;"></td>
                      <td style="min-width:120px;" class="text-center">
                        A le droit
                      </td>
                      <td style="min-width:120px;" class="text-center">
                        Comme le groupe
                      </td>
                      <td style="min-width:120px;" class="text-center">
                        N'a pas le droit
                      </td>
                      <td style="min-width:120px;" class="text-center">
                        Droit final
                      </td>
                    </tr>
                    <tr
                      v-for="row_rire in $store.state.items_rights_resources"
                      :key="row_rire.rire_id"
                    >
                      <td>{{ row_rire.rire_label }}</td>
                      <td class="text-center">
                        <input
                          type="radio"
                          class="form-check-input"
                          v-model="row_us.us_rights[row_rire.rire_id]"
                          :name="`${row_rire.rire_id}_right`"
                          :value="1"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="radio"
                          class="form-check-input"
                          v-model="row_us.us_rights[row_rire.rire_id]"
                          :name="`${row_rire.rire_id}_right`"
                          :value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="radio"
                          class="form-check-input"
                          v-model="row_us.us_rights[row_rire.rire_id]"
                          :name="`${row_rire.rire_id}_right`"
                          :value="-1"
                        />
                      </td>
                      <td
                        class="text-center"
                        v-html="rightCalculated(row_rire)"
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button>
          <div class="ms-auto"></div>

          <div class="form-check">
            <label class="form-check-label" for="us_active"
              >Utilisateur actif
              <input
                class="form-check-input"
                type="checkbox"
                name="us_active"
                id="us_active"
                v-model="row_us.us_active"
            /></label>
          </div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer cet utilisateur ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "UserWinEdit",
  components: {},
  props: {
    us_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      confirmdelete: false,
      row_us: { us_rights: {}, us_type: "" },
      password1: "",
      isValidPassword: false,
      errPassword: [],
      /// avatar
      avatarUrl: "",
      styleAvatar: "",
      fileSelected: { image: null, binary: null }
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.fileSelected = { image: null, binary: null };
        this.getContact();
      }
    },
    avatarUrl(val) {
      if (this.fileSelected.image) {
        this.styleAvatar =
          "background-image:url('" + this.fileSelected.binary + "')";
      } else {
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    }
  },
  computed: {
    // coolCoType() {
    //   return this.$_.result(
    //     this.$_.find(this.$store.state.items_rights_roles, {
    //       riro_id: this.row_us.us_type
    //     }),
    //     "riro_label",
    //     ""
    //   );
    // },
    // displayAcl() {
    //   let ret = [];
    //   this.$_.each(this.$store.state.items_rights_resources, (val, key) => {
    //     if (val.rire_id.indexOf("canSeeProduct_") == 0) {
    //       let f = this.$_.find(this.$store.state.items_rights_rolesresources, {
    //         rire_id: val.rire_id,
    //         riro_id: this.row_us.us_type
    //       });
    //       if (f) {
    //         let s = val.rire_id.split("_");
    //         ret.push(`${s[1]} - ${s[2]}`);
    //       }
    //     }
    //   });
    //   return ret;
    // },
    disabledType() {
      return false;
    },

    /**
     * défini de manière dinamyque les types  à afficher dans  le select type d'utilisateur
     * en fonction  de la route, va chercher les types corespondants renseignés  dans le  store
     */
    itemsTypes() {
      let itemsTypes = this.$store.state.items_rights_roles
        .map(item => {
          return { value: item.riro_id, text: item.riro_label };
        })
        .filter(item => {
          let ok = false,
            userType = this.$store.state.user.us_type;
          if (userType == "superadmin" || userType == "admin") ok = true;
          if (userType == item.value) ok = true;
          return ok;
        });
      return itemsTypes;
    }
  },

  created() {},
  mounted() {
    this.contactType = this.$route.meta.type;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },
    oninputPhoneMobile(evt) {
      if (evt.key === "Backspace" || evt.key === "Delete") return;
      let phone = this.$Utils.formatedPhone(this.row_us.us_phone);
      this.row_us.us_phone = phone;
      // this.$forceUpdate();
    },

    _hasRight(row_rire) {
      if (this.row_us.us_rights[row_rire.rire_id] === -1) return false;
      if (this.row_us.us_rights[row_rire.rire_id] === 1) return true;
      return !!this.$_.find(this.$store.state.items_rights_rolesresources, {
        rire_id: row_rire.rire_id,
        riro_id: this.row_us.us_type
      });
    },
    rightCalculated(row_rire) {
      if (this._hasRight(row_rire))
        return "<span class='text-success'>OUI</span>";
      return "<span class='text-danger'>NON</span>";
    },
    /*     classRightCalculated(row_rire) {
      if (this._hasRight(row_rire)) return "bg-success";
      return "bg-danger";
    }, */

    async getContact() {
      let params = { getacl: true };
      if (this.us_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/users/" + this.us_id,
        { params }
      );
      let row_us = response.data.data;
      this.password1 = "";
      row_us.us_password = "";
      //this.avatarUrl = `${this.$config.server_url}/uploads${row_us.us_avatar}`;
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/users/${this.us_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;

      // formater le téléphone joli
      if (this.row_us.us_phone)
        row_us.us_phone = this.$Utils.addSpaceToMobile(row_us.us_phone);

      if (!row_us.us_id) {
        row_us.us_type = this.$route.meta.contactsType;
      }
      if (!row_us.us_rights) row_us.us_rights = {};
      for (
        let iRe = 0;
        iRe < this.$store.state.items_rights_resources.length;
        iRe++
      ) {
        const ressource = this.$store.state.items_rights_resources[iRe];
        if (!row_us.us_rights[ressource.rire_id])
          row_us.us_rights[ressource.rire_id] = 0;
      }
      this.row_us = row_us;
    },

    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "us_name", text: "Nom" },
        { field: "us_firstname", text: "Prénom" },
        { field: "us_email", text: "Email" },
        { field: "us_type", text: "Type de contact" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_us[field.field]) err.push(field);
      }
      /*** vérification du mot de passe */
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          err.push({
            field: "us_password",
            text: this.errPassword[ierr]
          });
        }
      } else {
        this.row_us.us_password = this.password1;
      }
      if (!this.row_us.us_id) {
        if (!this.row_us.us_password) {
          err.push({
            field: "us_password",
            text: "Mot de passe obligatoire"
          });
        }
      }
      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    trimFields() {
      let tabField = [
        "us_name",
        "us_email",
        "us_firstname",
        "us_society",
        "us_phone"
      ];
      for (let i = 0; i < tabField.length; i++) {
        const field = tabField[i];
        this.row_us[field] = this.row_us[field].trim();
      }
    },
    async saveWin() {
      this.trimFields();
      // reformater le téléphone mobile
      if (this.row_us.us_phone)
        this.row_us.us_phone = this.$Utils.removeSpaceToMobile(
          this.row_us.us_phone
        );
      // console.log("this.row_us", this.row_us);

      let response;
      if (this.row_us.us_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/backoffice/1.0/users/" +
            this.row_us.us_id,
          this.row_us
        );
      } else {
        this.row_us.us_cgu_date = "0000-00-00 00:00:00";
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/users",
          this.row_us
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_us = response.data.data;
      if (this.fileSelected.image) {
        await this.saveImage(this.fileSelected.image, row_us.us_id);
      }
      // console.log("row_us", row_us);
      if (
        this.$store.state.user &&
        row_us.us_id == this.$store.state.user.us_id
      ) {
        this.$store.commit("set_user_simple", row_us);
      }
      this.$emit("input", false);
      this.$emit("saved", row_us.us_id);
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled", this.row_us.us_id);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      let us_id = this.row_us.us_id;
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/users/" + us_id
      );
      this.confirmdelete = false;
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("deleted", 0);
    },
    /// avatar
    async deleteAvatar() {
      this.fileSelected = { image: null, binary: null };
      this.avatarUrl = "";
      this.row_us.us_avatar = "";
      let row_us = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/users/" +
          this.us_id +
          "/avatar"
      );
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/users/${this.us_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;
      this.$store.commit("set_reloadAvatar", row_us);
    },
    changeAvatar() {
      this.$refs.inputImageAvatar.click();
    },
    fileJusteSelected($event) {
      // console.log("$event", $event);
      if (!$event.target.files.length) return;

      this.fileSelected.image = $event.target.files[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function(theFile) {
        return function(e) {
          me.fileSelected.binary = e.target.result;
          me.avatarUrl = e.target.result;
        };
      })($event.target.files[0]);
      reader.readAsDataURL($event.target.files[0]);
    },
    async saveImage(file, us_id) {
      if (!file) return;
      let formData = new FormData();
      formData.append("image", file, file.name);
      let row_us = await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/users/" +
          this.us_id +
          "/avatar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.fileSelected = { image: null, binary: null };
      this.$store.commit("set_reloadAvatar", row_us);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.delete-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #c8bfbf;
  box-shadow: 0 0 2px 0 grey;
  border-radius: 50%;
  padding: 7px;
}
</style>
